import { useState, useEffect } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Input,
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Autocomplete } from '@material-ui/lab'
import { FormSelectObject, FormSelectList } from 'models/deal'
import { DealFields, PipedriveTotal } from 'models/dealFields'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'

import format from 'date-fns/format'
import { searchOrgListByName, updateOrg } from 'api/org'
import { searchPersonListByName, updatePerson } from 'api/person'
import { contractDeal, lostDeal, updateDeal } from 'api/deal'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import GroupIcon from '@material-ui/icons/Group'
import ListAltIcon from '@material-ui/icons/ListAlt'
import TimelineIcon from '@material-ui/icons/Timeline'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import LinkIcon from '@material-ui/icons/Link'
import WcIcon from '@material-ui/icons/Wc'
import PostAddIcon from '@material-ui/icons/PostAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { Create } from '@material-ui/icons'
import { useAuthUser } from 'components/modules/AuthUserContext'
import { INDUSTRY_GRANDPARENT } from 'components/modules/Const'
import {
  getIndustryChild,
  getIndustryGrandchild,
  getIndustryParent,
} from 'api/industry'
import {
  IndustryChild,
  IndustryGrandchild,
  IndustryParent,
} from 'models/industry'
import { addActivity, getActivityTypes } from 'api/activity'
import { ActivityForm } from 'models/activity'
import { addHours } from 'date-fns/esm'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日(E)', { locale: this.locale })
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
    errorMessage: {
      color: 'red',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      margin: '5px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    border: {
      borderTop: '2px solid #e5e5e5 ',
    },
    dealButton: {
      margin: '16px',
    },
    topButton: {
      margin: theme.spacing(1),
    },
    betweenButton: {
      margin: theme.spacing(1),
      marginTop: '40px',
    },
    contractButton: {
      margin: theme.spacing(1),
      backgroundColor: '#08a742',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#30b560',
      },
    },
    lostButton: {
      margin: theme.spacing(1),
      backgroundColor: '#f94839',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#fa6559',
      },
    },
    pipedriveButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
    beforeModified: {
      color: 'green',
      margin: theme.spacing(1),
      paddingTop: '12px',
    },
    activityAddButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
  })
)

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const FinanceDisplay: React.VFC<PipedriveTotal> = ({
  pipedrive,
}): JSX.Element => {
  // 変数定義
  const authUser = useAuthUser()
  const classes = useStyles()

  const contactMethodList: FormSelectList = [{}]
  const openingPrefectureList: FormSelectList = [{}]
  let tmpIndustryParent = {}
  let tmpIndustryChild = {}
  let tmpIndustryGrandchild = {}

  if (pipedrive.org.industry_parent) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4058)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_parent)[0]
    tmpIndustryParent = {
      pipedrive_field_id: pipedrive.org.industry_parent,
      industry_parent_name: t.label,
    }
  }

  if (pipedrive.org.industry_child) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4059)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_child)[0]
    tmpIndustryChild = {
      pipedrive_field_id: pipedrive.org.industry_child,
      industry_child_name: t.label,
    }
  }

  if (pipedrive.org.industry_grandchild) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4074)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_grandchild)[0]
    tmpIndustryGrandchild = {
      pipedrive_field_id: pipedrive.org.industry_grandchild,
      industry_grandchild_name: t.label,
    }
  }

  // 問い合わせ経路
  pipedrive.dealFields
    .filter((v) => v.id == 12492)
    .map((contactMethod) =>
      contactMethod.options.map((e) =>
        contactMethodList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  contactMethodList.shift()

  // 開業予定都道府県
  pipedrive.orgFields
    ?.filter((v) => v.id == 4056)
    .map((openingPrefecture) =>
      openingPrefecture.options.map((e) =>
        openingPrefectureList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  openingPrefectureList.shift()

  // useState定義
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: undefined,
    message: '',
  })

  const [orgAddress, setOrgAddress] = useState<string>(pipedrive.org.address)
  const [personAddress, setPersonAddress] = useState<string | undefined>(
    pipedrive.person?.address
  )

  const [beforeModified, setbeforeModified] = useState<string>('')

  // 問い合わせ経路
  const [financyRoute, setFinancyRoute] = useState<FormSelectObject>({
    id: Number(pipedrive.deal.fi_inquiry_route),
    name: String(
      pipedrive.dealFields
        .filter((v) => v.id == 12492)
        .map((contactMethod) =>
          contactMethod.options
            .filter((v) => v.id == pipedrive.deal.fi_inquiry_route)
            .map((v) => v.label)
        )[0]
    ),
  })

  // 金融機関
  const [institution, setInstitution] = useState<FormSelectObject>({
    id: pipedrive.deal.institution?.value,
    name: pipedrive.deal.institution?.name,
  })

  const [institutionList, setInstitutionList] = useState<FormSelectList>([{}])

  // 支店名
  const [branch, setBranch] = useState<FormSelectObject>({
    id: pipedrive.deal.branch?.value,
    name: pipedrive.deal.branch?.name,
  })

  const [branchList, setBranchList] = useState<FormSelectList>([{}])

  // 金融機関担当者
  const [institutionManager, setInstitutionManager] =
    useState<FormSelectObject>({
      id: pipedrive.deal.institution_manager?.value,
      name: pipedrive.deal.institution_manager?.name,
    })

  const [institutionManagerList, setInstitutionManagerList] =
    useState<FormSelectList>([{}])

  // 紹介元（組織）
  const [referralSource, setReferralSource] = useState<FormSelectObject>({
    id: pipedrive.deal.fi_referral_source?.value,
    name: pipedrive.deal.fi_referral_source?.name,
  })

  const [referralSourceList, setReferralSourceList] = useState<FormSelectList>([
    {},
  ])

  // 紹介元担当者
  const [referralSourceManager, setReferralSourceManager] =
    useState<FormSelectObject>({
      id: pipedrive.deal.fi_referral_source_manager?.value,
      name: pipedrive.deal.fi_referral_source_manager?.name,
    })

  const [referralSourceManagerList, setReferralSourceManagerList] =
    useState<FormSelectList>([{}])

  // 口座振替先金融機関
  const [debitInstitution, setDebitInstitution] = useState<FormSelectObject>({
    id: pipedrive.deal.fi_debit_institution?.value,
    name: pipedrive.deal.fi_debit_institution?.name,
  })

  const [debitInstitutionList, setDebitInstitutionList] =
    useState<FormSelectList>([{}])

  // 口座振替先支店名
  const [debitBranch, setDebitBranch] = useState<FormSelectObject>({
    id: pipedrive.deal.fi_debit_branch?.value,
    name: pipedrive.deal.fi_debit_branch?.name,
  })

  const [debitBranchList, setDebitBranchList] = useState<FormSelectList>([{}])

  // 提携会社
  const [alCompany, setAlCompany] = useState<FormSelectObject>({
    id: pipedrive.deal.al_company?.value,
    name: pipedrive.deal.al_company?.name,
  })

  const [alCompanyList, setAlCompanyList] = useState<FormSelectList>([{}])

  // 提携会社
  const [alReferralCustomer, setAlReferralCustomer] =
    useState<FormSelectObject>({
      id: pipedrive.deal.al_referral_customer?.value,
      name: pipedrive.deal.al_referral_customer?.name,
    })

  // 提携紹介顧客
  const [alReferralCustomerList, setAlReferralCustomerList] =
    useState<FormSelectList>([{}])

  // 支援センター
  const [eaSupportCenter, setEaSupportCenter] = useState<FormSelectObject>({
    id: pipedrive.deal.ea_support_center?.value,
    name: pipedrive.deal.ea_support_center?.name,
  })

  const [eaSupportCenterList, setEaSupportCenterList] =
    useState<FormSelectList>([{}])

  // 支援センター担当者
  const [eaSupportCenterManager, setEaSupportCenterManager] =
    useState<FormSelectObject>({
      id: pipedrive.deal.ea_support_center_manager?.value,
      name: pipedrive.deal.ea_support_center_manager?.name,
    })

  const [eaSupportCenterManagerList, setEaSupportCenterManagerList] =
    useState<FormSelectList>([{}])

  // 開業予定都道府県
  const [openingPrefecture, setOpeningPrefecture] = useState<FormSelectObject>({
    id: Number(pipedrive.org.opening_prefecture),
    name: String(
      pipedrive.orgFields
        .filter((v) => v.id == 4056)
        .map((openingPrefecture) =>
          openingPrefecture.options
            .filter((v) => v.id == pipedrive.org.opening_prefecture)
            .map((v) => v.label)
        )[0]
    ),
  })

  const [stageList, setStageList] = useState<FormSelectList>(
    pipedrive.stageList
      .filter(
        (v) =>
          v.active_flag == true && v.pipeline_id == pipedrive.deal.pipeline_id
      )
      .map((stage) => {
        return {
          id: stage.id,
          name: stage.name,
        }
      })
  )

  const [loading, setLoading] = useState(true)

  const [industryParentList, setIndustryParentList] = useState<IndustryParent>([
    tmpIndustryParent,
  ])
  const [industryChildList, setIndustryChildList] = useState<IndustryChild>([
    tmpIndustryChild,
  ])
  const [industryGrandchildList, setIndustryGrandchildList] =
    useState<IndustryGrandchild>([tmpIndustryGrandchild])

  // 成約ボタンダイアログ設定
  const [contractOpen, setContractOpen] = useState(false)

  // 失注ボタンダイアログ設定
  const [lostOpen, setLostOpen] = useState(false)

  // アクティビティダイアログ設定
  const [activityAddOpen, setActivityAddOpen] = useState(false)

  // アクティビティリスト
  const [activityTypeList, setActivityTypeList] = useState<FormSelectList>([{}])

  // 【融】ヒアリング
  const defaultFinanceHearing = `■一回目

# 事業内容
# 業種
# 場所
# 時期
# 経験年数
# 業績
【前期】
【前々期】
# 借入希望額
# 自己資金
【事業用】
【個人】
# 創業の動機（創業計画書）
# サービスの内容
# セールスポイント（強み、コンセプト）
# 販売戦略
# 販売先
　会社名　所在地　％　　〆→　日
# 仕入先
　会社名　所在地　 ％　　〆→　日
# 外注先
　会社名　所在地　 ％　　〆→　日
# 従業員　名（内　家族　名　パート　名）
# 使い道（創業計画書、設備投資計画）
【設備】
購入予定設備の金額目安
【運転】
〇か月分で調整してください　or 　借入希望に合わせてほしい
※特になければ3か月分
# 使い道（企業概要書の場合のみ）
  ・今回の目的（簡単に）→

売上高
売上①
└単価○円（原価〇％）
売上②
└単価○円（原価〇％）

役員報酬
給料賃金：
外注費：
家賃：
広告宣伝費：

■追加2回目ヒアリング時

法定福利費（役員報酬＋給料賃金の10%）
水道光熱費：
旅費交通費：
車両費：
通信費：
接待交際費：
消耗品費：
支払手数料：
支払報酬料：
雑費：
その他`

  // 関数定義
  // カンマ区切りに切り出し
  const numberFormat = (num: number): string => {
    return num.toLocaleString()
  }

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    unregister,
    getValues,
  } = useForm<DealFields>({
    mode: 'all',
    defaultValues: {
      dealUserId: pipedrive.deal.user_id?.value,
      probability: pipedrive.deal.probability,
      expectedCloseDate: pipedrive.deal.expected_close_date,
      orgOwnerId: pipedrive.org.owner_id?.value,
      personOwnerId: pipedrive.person?.owner_id?.value,
      pipelineId: pipedrive.deal.pipeline_id,
      stageId: pipedrive.deal.stage_id,
      inquiryRoute: pipedrive.deal.fi_inquiry_route,
      institutionType: pipedrive.deal.fi_institution_type,
      institution: pipedrive.deal.institution?.value,
      branch: pipedrive.deal.branch?.value,
      institutionManager: pipedrive.deal.institution_manager?.value,
      interviewType: pipedrive.deal.fi_interview_type,
      desiredAmountFrom: pipedrive.deal.fi_desired_amount_from
        ? numberFormat(pipedrive.deal.fi_desired_amount_from)
        : '',
      desiredAmountTo: pipedrive.deal.fi_desired_amount_to
        ? numberFormat(pipedrive.deal.fi_desired_amount_to)
        : '',
      decidedAmount: pipedrive.deal.fi_decided_amount
        ? numberFormat(pipedrive.deal.fi_decided_amount)
        : '',
      financeInterviewDate: pipedrive.deal.fi_finance_interview_date,
      financeInterviewTime: pipedrive.deal.fi_finance_interview_time?.substring(
        0,
        5
      ),
      subscription: pipedrive.deal.subscription
        ? pipedrive.deal.subscription.split(',')
        : [],
      woProduction: pipedrive.deal.wo_production
        ? pipedrive.deal.wo_production.split(',')
        : [],
      requestedDocument: pipedrive.deal.fi_requested_document,
      referralSource: pipedrive.deal.fi_referral_source?.value,
      referralSourceManager: pipedrive.deal.fi_referral_source_manager?.value,
      desiredDate: pipedrive.deal.fi_desired_date,
      desiredRepaymentPeriod: pipedrive.deal.fi_desired_repayment_period,
      desiredDefermentPeriod: pipedrive.deal.fi_desired_deferment_period,
      desiredRepaymentDay: pipedrive.deal.fi_desired_repayment_day,
      interestRate: pipedrive.deal.fi_interest_rate,
      debitInstitution: pipedrive.deal.fi_debit_institution?.value,
      debitBranch: pipedrive.deal.fi_debit_branch?.value,
      financeHearing: pipedrive.deal.finance_hearing ?? defaultFinanceHearing,
      consultationContent: pipedrive.deal.consultation_content,
      consultationContentForm: pipedrive.deal.consultation_content_form,
      remark: pipedrive.deal.remark,
      remarkSecond: pipedrive.deal.remark_second,
      inSubject: pipedrive.deal.in_subject,
      inBillDate: pipedrive.deal.in_bill_date,
      inPaymentDeadline: pipedrive.deal.in_payment_deadline,
      inSendType: pipedrive.deal.in_send_type,
      inBillFlg: pipedrive.deal.in_bill_flg,
      inPaymentStatus: pipedrive.deal.in_payment_status,
      inPaymentDay: pipedrive.deal.in_payment_day,
      inOriginTransactionDeal: pipedrive.deal.in_origin_transaction_deal,
      suSubsidyType: pipedrive.deal.su_subsidy_type,
      suDocumentSubmitDate: pipedrive.deal.su_document_submit_date,
      suAdoptDate: pipedrive.deal.su_adopt_date,
      suExecutionDate: pipedrive.deal.su_execution_date,
      alServiceParent: pipedrive.deal.al_service_parent,
      alServiceChild: pipedrive.deal.al_service_child,
      alCompany: pipedrive.deal.al_company?.value,
      alReferralCustomer: pipedrive.deal.al_referral_customer?.value,
      paPaymentDeadline: pipedrive.deal.pa_payment_deadline,
      paApprovalNumber: pipedrive.deal.pa_approval_number,
      documentStorageLocation: pipedrive.deal.document_storage_location,
      contractStartDate: pipedrive.deal.contract_start_date,
      acContractPeriod: pipedrive.deal.ac_contract_period,
      eaSupportCenter: pipedrive.deal.ea_support_center?.value,
      eaSupportCenterManager: pipedrive.deal.ea_support_center_manager?.value,
      eaApplicationDate: pipedrive.deal.ea_application_date,
      orgName: pipedrive.org.name,
      orgAddress: pipedrive.org.address,
      orgAddressBuildings: pipedrive.org.address_buildings,
      representative: pipedrive.org.representative,
      representativeKana: pipedrive.org.representative_kana,
      orgTel: pipedrive.org.tel,
      orgTelSecond: pipedrive.org.tel_second,
      openingTime: pipedrive.org.opening_time,
      openingPrefecture: pipedrive.org.opening_prefecture,
      industryGrandparent: pipedrive.org.industry_grandparent,
      industryParent: pipedrive.org.industry_parent,
      industryChild: pipedrive.org.industry_child,
      industryGrandchild: pipedrive.org.industry_grandchild,
      foundedStatus: pipedrive.org.founded_status,
      eaPostCoronaPropose: pipedrive.org.ea_post_corona_propose,
      foundedDate: pipedrive.org.founded_date,
      startupType: pipedrive.org.startup_type,
      closingMonth: pipedrive.org.closing_month,
      existingLoanFirst: pipedrive.org.existing_loan_first,
      existingLoanAmountFirst: pipedrive.org.existing_loan_amount_first
        ? numberFormat(pipedrive.org.existing_loan_amount_first)
        : '',
      existingLoanDetailFirst: pipedrive.org.existing_loan_detail_first,
      existingLoanSecond: pipedrive.org.existing_loan_second,
      existingLoanAmountSecond: pipedrive.org.existing_loan_amount_second
        ? numberFormat(pipedrive.org.existing_loan_amount_second)
        : '',
      existingLoanDetailSecond: pipedrive.org.existing_loan_detail_second,
      existingLoanThird: pipedrive.org.existing_loan_third,
      existingLoanAmountThird: pipedrive.org.existing_loan_amount_third
        ? numberFormat(pipedrive.org.existing_loan_amount_third)
        : '',
      existingLoanDetailThird: pipedrive.org.existing_loan_detail_third,
      existingLoanForth: pipedrive.org.existing_loan_forth,
      existingLoanAmountForth: pipedrive.org.existing_loan_amount_forth
        ? numberFormat(pipedrive.org.existing_loan_amount_forth)
        : '',
      existingLoanDetailForth: pipedrive.org.existing_loan_detail_forth,
      existingLoanFifth: pipedrive.org.existing_loan_fifth,
      existingLoanAmountFifth: pipedrive.org.existing_loan_amount_fifth
        ? numberFormat(pipedrive.org.existing_loan_amount_fifth)
        : '',
      existingLoanDetailFifth: pipedrive.org.existing_loan_detail_fifth,
      firstBeforeSalesAmount: pipedrive.org.first_before_sales_amount
        ? numberFormat(pipedrive.org.first_before_sales_amount)
        : '',
      firstBeforeNetIncome: pipedrive.org.first_before_net_income
        ? numberFormat(pipedrive.org.first_before_net_income)
        : '',
      firstBeforeOfficerCompensationAmount: pipedrive.org
        .first_before_officer_compensation_amount
        ? numberFormat(pipedrive.org.first_before_officer_compensation_amount)
        : '',
      firstBeforeDepreciationAmount: pipedrive.org
        .first_before_depreciation_amount
        ? numberFormat(pipedrive.org.first_before_depreciation_amount)
        : '',
      secondBeforeSalesAmount: pipedrive.org.second_before_sales_amount
        ? numberFormat(pipedrive.org.second_before_sales_amount)
        : '',
      secondBeforeNetIncome: pipedrive.org.second_before_net_income
        ? numberFormat(pipedrive.org.second_before_net_income)
        : '',
      secondBeforeOfficerCompensationAmount: pipedrive.org
        .second_before_officer_compensation_amount
        ? numberFormat(pipedrive.org.second_before_officer_compensation_amount)
        : '',
      secondBeforeDepreciationAmount: pipedrive.org
        .second_before_depreciation_amount
        ? numberFormat(pipedrive.org.second_before_depreciation_amount)
        : '',
      office: pipedrive.org.office,
      orgRemark: pipedrive.org.remark,
      orgOldManager: pipedrive.org.old_manager,
      staffNumber: pipedrive.org.staff_number,
      personName: pipedrive.person?.name,
      personTel: pipedrive.person?.phone.map((v) => v.value).join(','),
      personEmail: pipedrive.person?.email.map((v) => v.value).join(','),
      mailSend: pipedrive.person?.mail_send,
      prFacebookAccount: pipedrive.person?.pr_facebook_account,
      prFacebookStatus: pipedrive.person?.pr_facebook_status,
      taxIntroduction: pipedrive.person?.tax_introduction,
      kana: pipedrive.person?.kana,
      housingLoan: pipedrive.person?.housing_loan,
      monthlyRepaymentAmountOfHousing: pipedrive.person
        ?.monthly_repayment_amount_of_housing
        ? numberFormat(pipedrive.person.monthly_repayment_amount_of_housing)
        : '',
      remainingRepaymentPeriodForHousing:
        pipedrive.person?.remaining_repayment_period_for_housing,
      housingBalance: pipedrive.person?.housing_balance
        ? numberFormat(pipedrive.person.housing_balance)
        : '',
      housingBuyTime: pipedrive.person?.housing_buy_time,
      personAddress: pipedrive.person?.address,
      personAddressBuildings: pipedrive.person?.address_buildings,
      borrowingPaymentDelay: pipedrive.person?.borrowing_payment_delay,
      borrowingPaymentDelayDetail:
        pipedrive.person?.borrowing_payment_delay_detail,
      borrowingDetail: pipedrive.person?.borrowing_detail,
      borrowingAmount: pipedrive.person?.borrowing_amount
        ? numberFormat(pipedrive.person.borrowing_amount)
        : '',
      borrowing: pipedrive.person?.borrowing,
      personRemark: pipedrive.person?.remark,
      delayedUtilityBillPayment: pipedrive.person?.delayed_utility_bill_payment,
      delayedUtilityBillPaymentDetail:
        pipedrive.person?.delayed_utility_bill_payment_detail,
      sex: pipedrive.person?.sex,
      industryExperience: pipedrive.person?.industry_experience,
      industryExperienceYears: pipedrive.person?.industry_experience_years,
      birthday: pipedrive.person?.birthday,
      delayedTax: pipedrive.person?.delayed_tax,
      delayedTaxDetail: pipedrive.person?.delayed_tax_detail,
      insurance: pipedrive.person?.insurance,
      remainingRepaymentPeriodForInsurance:
        pipedrive.person?.remaining_repayment_period_for_insurance,
      insuranceCompany: pipedrive.person?.insurance_company,
      insuranceCancellationRefundAmount: pipedrive.person
        ?.insurance_cancellation_refund_amount
        ? numberFormat(pipedrive.person.insurance_cancellation_refund_amount)
        : '',
      resource: pipedrive.person?.resource
        ? numberFormat(pipedrive.person.resource)
        : '',
      otherResource: pipedrive.person?.other_resource
        ? numberFormat(pipedrive.person.other_resource)
        : '',
      rentOrHouse: pipedrive.person?.rent_or_house,
      carLoan: pipedrive.person?.car_loan,
      selfBankruptcy: pipedrive.person?.self_bankruptcy,
      selfBankruptcyAmount: pipedrive.person?.self_bankruptcy_amount
        ? numberFormat(pipedrive.person.self_bankruptcy_amount)
        : '',
      selfBankruptcyDetails: pipedrive.person?.self_bankruptcy_details,
      spouse: pipedrive.person?.spouse,
      spouseIndustry: pipedrive.person?.spouse_industry,
      spouseSalary: pipedrive.person?.spouse_salary
        ? numberFormat(pipedrive.person.spouse_salary)
        : '',
      personTelSecond: pipedrive.person?.tel_second,
      customerRank: pipedrive.person?.customer_rank,
      orgPostCode: pipedrive.org.post_code,
      personPostCode: pipedrive.person?.post_code,
      cardLoan: pipedrive.person?.card_loan,
      cardLoanAmount: pipedrive.person?.card_loan_amount
        ? numberFormat(pipedrive.person.card_loan_amount)
        : '',
      taxAdvisoryContract: pipedrive.person?.tax_advisory_contract,
      taxAdvisoryContractAmount: pipedrive.person?.tax_advisory_contract_amount
        ? numberFormat(pipedrive.person.tax_advisory_contract_amount)
        : '',
      financingInfo: pipedrive.person?.financing_info,
    },
  })

  const {
    register: registerTask,
    control: controlTask,
    setValue: setValueTask,
    trigger: triggerTask,
    formState: { errors: errorsTask },
    handleSubmit: handleSubmitTask,
    reset: resetTask,
  } = useForm<ActivityForm>({
    mode: 'all',
    defaultValues: {
      taskAssign: authUser?.pipedrive_id,
      taskStartDate: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  // 登録時処理
  const handleOnSubmit = async (params: DealFields) => {
    setLoading(true)

    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken

    // 創業状況は【統】創業状況にも登録するためIDではなく文字列に変換
    params.foundedStatus = String(
      pipedrive.orgFields
        .filter((v) => v.id == 4045)
        .map(
          (foundedStatus) =>
            foundedStatus.options
              .filter((v) => v.id == Number(params.foundedStatus))
              .map((v) => v.label)[0]
        )
    )

    // 【統】業種（祖父母）、【統】業種（親）登録用に文字列変換
    params.statisticsIndustryGrandparent = String(
      pipedrive.orgFields
        .filter((v) => v.id == 4073)
        .map(
          (industryGrandparent) =>
            industryGrandparent.options
              .filter((v) => v.id == params.industryGrandparent)
              .map((v) => v.label)[0]
        )
    )

    params.statisticsIndustryParent = String(
      pipedrive.orgFields
        .filter((v) => v.id == 4058)
        .map(
          (industryParent) =>
            industryParent.options
              .filter((v) => v.id == params.industryParent)
              .map((v) => v.label)[0]
        )
    )

    try {
      const dealResult = await updateDeal(params, apiToken)
      if (!dealResult) throw new Error('deal update error')
      const orgResult = await updateOrg(params, apiToken)
      if (!orgResult) throw new Error('org update error')
      const personResult = await updatePerson(params, apiToken)
      if (!personResult) throw new Error('person update error')

      setSnackBar({
        open: true,
        severity: 'success',
        message: '取引情報を更新しました',
      })
    } catch (e) {
      console.log(e)
      setSnackBar({
        open: true,
        severity: 'error',
        message: '取引情報の更新に失敗しました',
      })
    }

    const now = new Date()

    setbeforeModified(
      now.getFullYear() +
        '年' +
        (now.getMonth() + 1) +
        '月' +
        now.getDate() +
        '日 ' +
        now.getHours() +
        '時' +
        now.getMinutes() +
        '分'
    )

    setLoading(false)
  }

  const handleClickContractOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため成約に変更できません',
      })
      return
    }
    setContractOpen(true)
  }

  // 結果ダイアログ用
  const handleSnackBarClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  const handleContractClose = () => {
    setContractOpen(false)
  }

  const handleClickContract = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 成約処理
    contractDeal(pipedrive.deal.id, apiToken)
    pipedrive.deal.status = 'won'
    setContractOpen(false)
  }

  const handleClickLostOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため失注に変更できません',
      })
      return
    }
    setLostOpen(true)
  }

  const handleLostClose = () => {
    setLostOpen(false)
  }

  const handleClickLost = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 失注処理
    lostDeal(pipedrive.deal.id, getValues('lostReason'), apiToken)
    pipedrive.deal.status = 'lost'
    setLostOpen(false)
  }

  const handleClickActivityAddOpen = async () => {
    // 初回のみアクティビティリストを取得
    if (!Object.keys(activityTypeList[0]).length) {
      // アクティビティタイプを取得
      const activityTypes = await getActivityTypes()

      setActivityTypeList(
        activityTypes
          .filter((v) => v.active_flag == true)
          .map((v) => {
            return {
              id: v.id,
              name: v.name,
            }
          })
      )
    }

    setActivityAddOpen(true)
  }

  const handleActivityAddClose = () => {
    setActivityAddOpen(false)
  }

  const handleClickActivityAdd = async (params: ActivityForm) => {
    // アクティビティ新規作成
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    try {
      // 標準時基準のため9時間調整
      if (params.taskStartTime) {
        const taskStartClock = format(
          addHours(
            new Date(params.taskStartDate + ' ' + params.taskStartTime),
            -9
          ),
          'yyyy-MM-dd HH:mm'
        )

        params.taskStartDate = taskStartClock.slice(0, 10)
        params.taskStartTime = taskStartClock.slice(-5)
      }
      // アクティビティ新規作成
      const ret = await addActivity(params, apiToken)

      if (!ret) throw new Error('add activity error')

      resetTask()
      setActivityAddOpen(false)
      setSnackBar({
        open: true,
        severity: 'success',
        message: 'アクティビティを作成しました',
      })
    } catch (e) {
      console.log(e)
      setSnackBar({
        open: true,
        severity: 'error',
        message: 'アクティビティ作成に失敗しました',
      })
    }
  }

  // 組織郵便番号変更時
  const setOrgAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setOrgAddress(result.data?.fullAddress || '')
      })
  }

  // 人物郵便番号変更時
  const setPersonAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setPersonAddress(result.data?.fullAddress || '')
      })
  }

  // 起業する業種（親）の選択肢を準備
  const setIndustryParentFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryParentList([{}])
      setIndustryChildList([{}])
      setIndustryGrandchildList([{}])
      setValue('industryParent', 0)
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
      trigger('industryParent')
      trigger('industryChild')
      trigger('industryGrandchild')
      return
    }
    const industryGrandparentId: number = INDUSTRY_GRANDPARENT.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    const industryParentList = await getIndustryParent(industryGrandparentId)

    setIndustryParentList(
      industryParentList.map((v) => {
        return {
          id: v.id,
          pipedrive_field_id: v.pipedrive_field_id,
          industry_parent_name: v.industry_parent_name,
        }
      })
    )

    setIndustryChildList([{}])
    setIndustryGrandchildList([{}])
    setValue('industryParent', 0)
    setValue('industryChild', 0)
    setValue('industryGrandchild', 0)
    trigger('industryParent')
    trigger('industryChild')
    trigger('industryGrandchild')
  }

  // 起業する業種（子）の選択肢を準備
  const setIndustryChildFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryChildList([{}])
      setIndustryGrandchildList([{}])
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
      trigger('industryChild')
      trigger('industryGrandchild')
      return
    }
    const industryParentId = industryParentList.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    if (industryParentId) {
      const industryChildList = await getIndustryChild(industryParentId)

      setIndustryChildList(
        industryChildList.map((v) => {
          return {
            id: v.id,
            pipedrive_field_id: v.pipedrive_field_id,
            industry_child_name: v.industry_child_name,
          }
        })
      )

      setIndustryGrandchildList([{}])
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
      trigger('industryChild')
      trigger('industryGrandchild')
    }
  }

  // 起業する業種（孫）の選択肢を準備
  const setIndustryGrandchildFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryGrandchildList([{}])
      setValue('industryGrandchild', 0)
      trigger('industryGrandchild')
      return
    }

    const industryChildId = industryChildList.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    if (industryChildId) {
      const industryGrandchildList = await getIndustryGrandchild(
        industryChildId
      )

      setIndustryGrandchildList(
        industryGrandchildList.map((v) => {
          return {
            id: v.id,
            pipedrive_field_id: v.pipedrive_field_id,
            industry_grandchild_name: v.industry_grandchild_name,
          }
        })
      )

      setValue('industryGrandchild', 0)
      trigger('industryGrandchild')
    }
  }

  // パイプライン変更時ステージ更新
  const setStage = (e: React.ChangeEvent<{ value: unknown }>): void => {
    setStageList(
      pipedrive.stageList
        .filter((v) => v.pipeline_id == Number(e.target.value))
        .map((v) => {
          return {
            id: v.id,
            name: v.name,
          }
        })
    )
  }

  // 金融機関
  useEffect(() => {
    setLoading(false)
    const setOrgForInstitution = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(institution.name)

      setInstitutionList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (institution.name) {
      setOrgForInstitution()
    }
  }, [institution])

  // 支店
  useEffect(() => {
    const setOrgForBranch = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(branch.name)

      setBranchList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (branch.name) {
      setOrgForBranch()
    }
  }, [branch])

  // 金融機関担当者
  useEffect(() => {
    const setPersonForInstitutionManager = async () => {
      // 該当の人物を取得
      const personList = await searchPersonListByName(institutionManager.name)

      setInstitutionManagerList(
        personList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (institutionManager.name) {
      setPersonForInstitutionManager()
    }
  }, [institutionManager])

  // 紹介元（組織）
  useEffect(() => {
    const setOrgForReferralSource = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(referralSource.name)

      setReferralSourceList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (referralSource.name) {
      setOrgForReferralSource()
    }
  }, [referralSource])

  // 紹介元担当者
  useEffect(() => {
    const setPersonForReferralSourceManager = async () => {
      // 該当の人物を取得
      const personList = await searchPersonListByName(
        referralSourceManager.name
      )

      setReferralSourceManagerList(
        personList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (referralSourceManager.name) {
      setPersonForReferralSourceManager()
    }
  }, [referralSourceManager])

  // 口座振替先金融機関
  useEffect(() => {
    const setOrgForDebitInstitution = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(debitInstitution.name)

      setDebitInstitutionList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (debitInstitution.name) {
      setOrgForDebitInstitution()
    }
  }, [debitInstitution])

  // 口座振替先支店
  useEffect(() => {
    const setOrgForDebitBranch = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(debitBranch.name)

      setDebitBranchList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (debitBranch.name) {
      setOrgForDebitBranch()
    }
  }, [debitBranch])

  // 提携会社
  useEffect(() => {
    const setOrgForAlCompany = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(alCompany.name)

      setAlCompanyList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (alCompany.name) {
      setOrgForAlCompany()
    }
  }, [alCompany])

  // 提携紹介顧客
  useEffect(() => {
    const setPersonForAlReferralCustomer = async () => {
      // 該当の人物を取得
      const personList = await searchPersonListByName(alReferralCustomer.name)

      setAlReferralCustomerList(
        personList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (alReferralCustomer.name) {
      setPersonForAlReferralCustomer()
    }
  }, [alReferralCustomer])

  // 支援センター
  useEffect(() => {
    const setOrgForEaSupportCenter = async () => {
      // 該当の組織を取得
      const orgList = await searchOrgListByName(eaSupportCenter.name)

      setEaSupportCenterList(
        orgList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (eaSupportCenter.name) {
      setOrgForEaSupportCenter()
    }
  }, [eaSupportCenter])

  // 支援センター担当者
  useEffect(() => {
    const setPersonForEaSupportCenterManager = async () => {
      // 該当の人物を取得
      const personList = await searchPersonListByName(
        eaSupportCenterManager.name
      )

      setEaSupportCenterManagerList(
        personList.map((v) => {
          return {
            id: v.item.id,
            name: v.item.name,
          }
        })
      )
    }

    if (eaSupportCenterManager.name) {
      setPersonForEaSupportCenterManager()
    }
  }, [eaSupportCenterManager])

  // 初期読み込み内容
  useEffect(() => {
    // 業種（祖父母）のみ選択されていた場合、業種（親）のリストを表示
    if (pipedrive.org.industry_grandparent && !pipedrive.org.industry_parent)
      setIndustryParentFormList(pipedrive.org.industry_grandparent)
  }, [])

  // 読み込み終了までローディング
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const copyMonthUrl = () => {
    if (!pipedrive.person?.hash) {
      alert('ハッシュ値が登録されていません。')
      return
    }

    const url =
      'https://so-labo.co.jp/financing-info-register-month?pid=' +
      pipedrive.person?.id +
      '&hash=' +
      pipedrive.person?.hash

    navigator.clipboard.writeText(url)
    alert('URLをコピーしました。\n' + url)
  }

  const copyYearUrl = () => {
    if (!pipedrive.person?.hash) {
      alert('ハッシュ値が登録されていません。')
      return
    }

    const url =
      'https://so-labo.co.jp/financing-info-register-year?pid=' +
      pipedrive.person?.id +
      '&hash=' +
      pipedrive.person?.hash

    navigator.clipboard.writeText(url)
    alert('URLをコピーしました。\n' + url)
  }

  return (
    <>
      <form onSubmit={handleSubmitTask(handleClickActivityAdd)}>
        <Input
          {...registerTask('dealId')}
          id="dealId"
          type="hidden"
          value={pipedrive.deal.id}
        />
        <Input
          {...registerTask('orgId')}
          id="orgId"
          type="hidden"
          value={pipedrive.org.id}
        />
        <Input
          {...registerTask('personId')}
          id="personId"
          type="hidden"
          value={pipedrive.person?.id}
        />
        <Dialog
          open={activityAddOpen}
          onClose={handleActivityAddClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            アクティビティ新規作成
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography gutterBottom>
                ※ 必要情報を入力して登録してください
              </Typography>
            </DialogContentText>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid container spacing={3} item xs={12}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      {...registerTask('taskName', {
                        required: '※ アクティビティ名を入力してください',
                      })}
                      variant="standard"
                      fullWidth
                      id="taskName"
                      label={
                        <span style={{ color: 'red' }}>アクティビティ名</span>
                      }
                      autoComplete="taskName"
                    />
                  </FormControl>
                  {errorsTask.taskName && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskName.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      id="owner-select-label"
                      style={{ color: 'red' }}
                    >
                      タイプ
                    </InputLabel>
                    <Controller
                      name="taskType"
                      control={controlTask}
                      rules={{
                        required: '※ タイプを選択してください',
                      }}
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value="">選択してください</MenuItem>
                          {activityTypeList.map((activityType) => (
                            <MenuItem
                              key={activityType.id}
                              value={activityType.name}
                            >
                              {activityType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errorsTask.taskType && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskType.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      id="owner-select-label"
                      style={{ color: 'red' }}
                    >
                      オーナー
                    </InputLabel>
                    <Controller
                      name="taskAssign"
                      control={controlTask}
                      rules={{
                        required: '※ オーナーを選択してください',
                      }}
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value="">選択してください</MenuItem>
                          {pipedrive.ownerList
                            .filter((v) => v.active_flag == true)
                            .map((dealOwner) => (
                              <MenuItem key={dealOwner.id} value={dealOwner.id}>
                                {dealOwner.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errorsTask.taskAssign && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskAssign.message}
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3} item xs={12}>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <Controller
                      name="taskStartDate"
                      control={controlTask}
                      rules={{
                        required: '※ 開始日を入力してください',
                      }}
                      render={({ field }) => (
                        <MuiPickersUtilsProvider
                          utils={ExtendedUtils}
                          locale={jaLocale}
                        >
                          <KeyboardDatePicker
                            {...field}
                            label={<span style={{ color: 'red' }}>開始日</span>}
                            value={field.value}
                            format="yyyy-MM-dd"
                            autoOk={true}
                            variant="inline"
                            onChange={(_date, v) => {
                              setValueTask('taskStartDate', v)
                              triggerTask('taskStartDate')
                            }}
                            invalidDateMessage="※ フォーマットに従って入力してください"
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </FormControl>
                  {errorsTask.taskStartDate && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskStartDate.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      {...registerTask('taskStartTime', {
                        pattern: {
                          value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                          message: '※ 時間形式で入力してください',
                        },
                      })}
                      variant="standard"
                      fullWidth
                      id="taskStartTime"
                      label="開始時間"
                      autoComplete="taskStartTime"
                    />
                  </FormControl>
                  {errorsTask.taskStartTime && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskStartTime.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      {...registerTask('taskDuration', {
                        pattern: {
                          value: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
                          message: '※ 時間形式で入力してください',
                        },
                      })}
                      variant="standard"
                      fullWidth
                      id="taskDuration"
                      label="継続時間"
                      autoComplete="taskDuration"
                    />
                  </FormControl>
                  {errorsTask.taskDuration && (
                    <span className={classes.errorMessage}>
                      {errorsTask.taskDuration.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      {...registerTask('taskMemo')}
                      multiline
                      variant="outlined"
                      fullWidth
                      id="taskMemo"
                      label="メモ"
                      autoComplete="taskMemo"
                      minRows={3}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleActivityAddClose}
              color="primary"
              variant="outlined"
            >
              キャンセル
            </Button>
            <Button
              type="submit"
              onClick={handleSubmitTask(async (data) => {
                handleClickActivityAdd(data)
              })}
              color="primary"
              autoFocus
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Input
          {...register('dealId')}
          id="dealId"
          type="hidden"
          value={pipedrive.deal.id}
        />
        <Input
          {...register('orgId')}
          id="orgId"
          type="hidden"
          value={pipedrive.org.id}
        />
        <Input
          {...register('personId')}
          id="personId"
          type="hidden"
          value={pipedrive.person?.id}
        />
        <Grid container spacing={3} item xs={12}>
          <Grid container xs={12} md={6} lg={3}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.dealButton}
              startIcon={<MonetizationOnIcon />}
            >
              取引
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className={classes.pipedriveButton}
              target="_blank"
              startIcon={<LinkIcon />}
              href={`https://solabo.pipedrive.com/deal/${pipedrive.deal.id}`}
            >
              Pipedrive
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.activityAddButton}
              startIcon={<PostAddIcon />}
              color="primary"
              onClick={() => handleClickActivityAddOpen()}
            >
              アクティビティ作成
            </Button>

            {beforeModified && (
              <span className={classes.beforeModified}>
                前回更新時間：{beforeModified}
              </span>
            )}
          </Grid>
          <Grid container xs={12} md={6} lg={9} justify="flex-end">
            {pipedrive.deal.status == 'open' && (
              <>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.contractButton}
                  startIcon={<ThumbUpIcon />}
                  onClick={async () => {
                    // 成約するためのその他エラーチェック
                    const formError = await trigger()
                    handleClickContractOpen(formError)
                  }}
                >
                  成約
                </Button>
                <Dialog
                  open={contractOpen}
                  onClose={handleContractClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">成約確認</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Typography gutterBottom>
                        取引のステータスを「
                        <span style={{ color: '#08a742', fontWeight: 'bold' }}>
                          成約
                        </span>
                        」へ変更します。よろしいですか？
                      </Typography>
                      <Typography color="error">
                        ※ 入力内容が同時に登録されます
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleContractClose}
                      color="primary"
                      variant="outlined"
                    >
                      キャンセル
                    </Button>
                    <Button
                      onClick={handleSubmit(async (data) => {
                        await handleOnSubmit(data)
                        handleClickContract()
                      })}
                      color="primary"
                      autoFocus
                      variant="contained"
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.lostButton}
                  startIcon={<ThumbDownIcon />}
                  onClick={async () => {
                    // 失注するためのその他エラーチェック
                    const formError = await trigger()
                    handleClickLostOpen(formError)
                  }}
                >
                  失注
                </Button>
                <Dialog
                  open={lostOpen}
                  onClose={handleLostClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">失注確認</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Typography gutterBottom>
                        取引のステータスを「
                        <span style={{ color: '#f94839', fontWeight: 'bold' }}>
                          失注
                        </span>
                        」へ変更します。よろしいですか？
                      </Typography>
                      <Typography color="error">
                        ※ 入力内容が同時に登録されます
                      </Typography>
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="owner-select-label">失注理由</InputLabel>
                      <Controller
                        name="lostReason"
                        control={control}
                        rules={{ required: '※ 失注理由を選択してください' }}
                        render={({ field }) => (
                          <Select {...field}>
                            <MenuItem value="">選択してください</MenuItem>
                            {pipedrive.dealFields
                              .filter((v) => v.id == 12475)
                              .map((lostReason) =>
                                lostReason.options
                                  .filter(
                                    (v) =>
                                      v.label.match('【共】') ||
                                      v.label.match('【融】')
                                  )
                                  .map((e) => (
                                    <MenuItem key={e.id} value={e.label}>
                                      {e.label}
                                    </MenuItem>
                                  ))
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors.lostReason && (
                      <span className={classes.errorMessage}>
                        {errors.lostReason.message}
                      </span>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleLostClose}
                      color="primary"
                      variant="outlined"
                    >
                      キャンセル
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit(async (data) => {
                        await handleOnSubmit(data)
                        handleClickLost()
                      })}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {pipedrive.deal.status == 'won' && (
              <>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.contractButton}
                >
                  成約済み
                </Button>
              </>
            )}
            {pipedrive.deal.status == 'lost' && (
              <>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.lostButton}
                >
                  失注済み
                </Button>
              </>
            )}
            {pipedrive.deal.status == 'deleted' && (
              <>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.lostButton}
                >
                  削除済み
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">オーナー</InputLabel>
                <Controller
                  name="dealUserId"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.ownerList
                        .filter((v) => v.active_flag == true)
                        .map((dealOwner) => (
                          <MenuItem key={dealOwner.id} value={dealOwner.id}>
                            {dealOwner.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                  パイプライン
                </InputLabel>
                <Controller
                  name="pipelineId"
                  control={control}
                  rules={{
                    required: '※ パイプラインを選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setStage(e)
                      }}
                    >
                      <MenuItem value="">
                        <em>選択してください</em>
                      </MenuItem>
                      {pipedrive.pipelineList.map((pipeline) => (
                        <MenuItem key={pipeline.id} value={pipeline.id}>
                          {pipeline.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.pipelineId && (
                <span className={classes.errorMessage}>
                  {errors.pipelineId.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                  ステージ
                </InputLabel>
                <Controller
                  name="stageId"
                  control={control}
                  rules={{
                    required: '※ ステージを選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger([
                          'expectedCloseDate',
                          'institution',
                          'institutionManager',
                          'branch',
                          'institutionType',
                          'decidedAmount',
                          'desiredRepaymentPeriod',
                          'desiredDefermentPeriod',
                        ])
                      }}
                    >
                      <MenuItem value="">
                        <em>選択してください</em>
                      </MenuItem>
                      {stageList.map((stage) => (
                        <MenuItem key={stage.id} value={stage.id}>
                          {stage.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.stageId && (
                <span className={classes.errorMessage}>
                  {errors.stageId.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('probability', {
                    pattern: {
                      value: /^([1-9]?[0-9]|100)$/,
                      message: '※ 0から100の数値を入力してください',
                    },
                  })}
                  variant="standard"
                  fullWidth
                  id="probability"
                  label="確率"
                  autoComplete="probability"
                />
              </FormControl>
              {errors.probability && (
                <span className={classes.errorMessage}>
                  {errors.probability.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="expectedCloseDate"
                  control={control}
                  rules={{
                    required:
                      watch('stageId') == 13
                        ? '※ 終了予定日を選択してください'
                        : false,
                  }}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label={
                          <span
                            style={
                              watch('stageId') == 13 ? { color: 'red' } : {}
                            }
                          >
                            終了予定日
                          </span>
                        }
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('expectedCloseDate', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
              {errors.expectedCloseDate && (
                <span className={classes.errorMessage}>
                  {errors.expectedCloseDate.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('inquiryRoute')}
                  id="combo-box-demo"
                  options={contactMethodList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) => option.id == financyRoute.id}
                  value={financyRoute}
                  onChange={(_e, options) => {
                    setValue('inquiryRoute', options?.id)
                    setFinancyRoute({ id: options?.id, name: options?.name })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="問い合わせ経路" />
                  )}
                />
              </FormControl>
              {errors.inquiryRoute && (
                <span className={classes.errorMessage}>
                  {errors.inquiryRoute.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  相談内容（フォーム）
                </InputLabel>
                <Controller
                  name="consultationContentForm"
                  control={control}
                  rules={{
                    required: '※ 相談内容（フォーム）を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12567)
                        .map((consultationContentForm) =>
                          consultationContentForm.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.consultationContentForm && (
                <span className={classes.errorMessage}>
                  {errors.consultationContentForm.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('referralSource')}
                  id="combo-box-demo"
                  options={referralSourceList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) => option.id == referralSource.id}
                  value={referralSource}
                  onChange={(_e, options) => {
                    setValue('referralSource', options?.id)
                    setReferralSource({ id: options?.id, name: options?.name })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="紹介元組織"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setReferralSource({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('referralSourceManager')}
                  id="combo-box-demo"
                  options={referralSourceManagerList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == referralSourceManager.id
                  }
                  value={referralSourceManager}
                  onChange={(_e, options) => {
                    setValue('referralSourceManager', options?.id)
                    setReferralSourceManager({
                      id: options?.id,
                      name: options?.name,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="紹介元担当者"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setReferralSourceManager({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  融資相談先金融機関
                </InputLabel>
                <Controller
                  name="institutionType"
                  control={control}
                  rules={{
                    required: '※ 融資相談先金融機関を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12513)
                        .map((institutionType) =>
                          institutionType.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.institutionType && (
                <span className={classes.errorMessage}>
                  {errors.institutionType.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('institution', {
                    required: [12, 13, 81].includes(watch('stageId'))
                      ? '※ 金融機関を選択してください'
                      : false,
                  })}
                  id="combo-box-demo"
                  options={institutionList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) => option.id == institution.id}
                  value={institution}
                  onChange={(_e, options) => {
                    setValue('institution', options?.id)
                    setInstitution({ id: options?.id, name: options?.name })
                    trigger('institution')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          style={
                            [12, 13, 81].includes(watch('stageId'))
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          金融機関
                        </span>
                      }
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setInstitution({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
              {errors.institution && (
                <span className={classes.errorMessage}>
                  {errors.institution.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('branch', {
                    required: [12, 13, 81].includes(watch('stageId'))
                      ? '※ 支店名を選択してください'
                      : false,
                  })}
                  id="combo-box-demo"
                  options={branchList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) => option.id == branch.id}
                  value={branch}
                  onChange={(_e, options) => {
                    setValue('branch', options?.id)
                    setBranch({ id: options?.id, name: options?.name })
                    trigger('branch')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          style={
                            [12, 13, 81].includes(watch('stageId'))
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          支店名
                        </span>
                      }
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setBranch({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
              {errors.branch && (
                <span className={classes.errorMessage}>
                  {errors.branch.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('institutionManager', {
                    required: [12, 13, 81].includes(watch('stageId'))
                      ? '※ 金融機関担当者を選択してください'
                      : false,
                  })}
                  id="combo-box-demo"
                  options={institutionManagerList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == institutionManager.id
                  }
                  value={institutionManager}
                  onChange={(_e, options) => {
                    setValue('institutionManager', options?.id)
                    setInstitutionManager({
                      id: options?.id,
                      name: options?.name,
                    })
                    trigger('institutionManager')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          style={
                            [12, 13, 81].includes(watch('stageId'))
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          金融機関担当者
                        </span>
                      }
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setInstitutionManager({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
              {errors.institutionManager && (
                <span className={classes.errorMessage}>
                  {errors.institutionManager.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">面談タイプ</InputLabel>
                <Controller
                  name="interviewType"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12623)
                        .map((interviewType) =>
                          interviewType.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('desiredAmountFrom', {
                    required: '※ 借入希望額Fromを入力してください',
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                  })}
                  variant="standard"
                  fullWidth
                  id="desiredAmountFrom"
                  label={<span style={{ color: 'red' }}>借入希望額From</span>}
                  autoComplete="desiredAmountFrom"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const amount = e.target.value.replace(/,/g, '')
                      setValue(
                        'desiredAmountFrom',
                        numberFormat(Number(amount))
                      )
                    }
                  }}
                />
              </FormControl>
              {errors.desiredAmountFrom && (
                <span className={classes.errorMessage}>
                  {errors.desiredAmountFrom.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('desiredAmountTo', {
                    required: '※ 借入希望額Toを入力してください',
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                  })}
                  variant="standard"
                  fullWidth
                  id="desiredAmountTo"
                  label={<span style={{ color: 'red' }}>借入希望額To</span>}
                  autoComplete="desiredAmountTo"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const amount = e.target.value.replace(/,/g, '')
                      setValue('desiredAmountTo', numberFormat(Number(amount)))
                    }
                  }}
                />
              </FormControl>
              {errors.desiredAmountTo && (
                <span className={classes.errorMessage}>
                  {errors.desiredAmountTo.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('decidedAmount', {
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                    required:
                      watch('stageId') == 13
                        ? '※ 決定金額を入力してください'
                        : false,
                  })}
                  variant="standard"
                  fullWidth
                  id="decidedAmount"
                  label={
                    <span
                      style={watch('stageId') == 13 ? { color: 'red' } : {}}
                    >
                      決定金額
                    </span>
                  }
                  autoComplete="decidedAmount"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const amount = e.target.value.replace(/,/g, '')
                      setValue('decidedAmount', numberFormat(Number(amount)))
                    }
                  }}
                />
              </FormControl>
              {errors.decidedAmount && (
                <span className={classes.errorMessage}>
                  {errors.decidedAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="financeInterviewDate"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="公庫面談日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('financeInterviewDate', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
              {errors.financeInterviewDate && (
                <span className={classes.errorMessage}>
                  {errors.financeInterviewDate.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('financeInterviewTime', {
                    pattern: {
                      value: /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/,
                      message: '※ 時間形式で入力してください',
                    },
                  })}
                  variant="standard"
                  fullWidth
                  id="financeInterviewTime"
                  label="公庫面談時間"
                  autoComplete="financeInterviewTime"
                />
              </FormControl>
              {errors.financeInterviewTime && (
                <span className={classes.errorMessage}>
                  {errors.financeInterviewTime.message}
                </span>
              )}
            </Grid>

            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="desiredDate"
                    control={control}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label="借入希望日"
                          value={field.value}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          onChange={(_date, v) => {
                            setValue('desiredDate', v)
                          }}
                          invalidDateMessage="※ フォーマットに従って入力してください"
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="owner-select-label"
                    style={watch('stageId') == 13 ? { color: 'red' } : {}}
                  >
                    希望返済期間
                  </InputLabel>
                  <Controller
                    name="desiredRepaymentPeriod"
                    control={control}
                    rules={{
                      required:
                        watch('stageId') == 13
                          ? '※ 希望返済期間を選択してください'
                          : false,
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.dealFields
                          .filter((v) => v.id == 12588)
                          .map((desiredRepaymentPeriod) =>
                            desiredRepaymentPeriod.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="owner-select-label"
                    style={watch('stageId') == 13 ? { color: 'red' } : {}}
                  >
                    希望据置期間
                  </InputLabel>
                  <Controller
                    name="desiredDefermentPeriod"
                    control={control}
                    rules={{
                      required:
                        watch('stageId') == 13
                          ? '※ 希望据置期間を選択してください'
                          : false,
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.dealFields
                          .filter((v) => v.id == 12589)
                          .map((desiredDefermentPeriod) =>
                            desiredDefermentPeriod.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">返済希望日</InputLabel>
                  <Controller
                    name="desiredRepaymentDay"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.dealFields
                          .filter((v) => v.id == 12587)
                          .map((desiredRepaymentDay) =>
                            desiredRepaymentDay.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...register('interestRate', {
                      pattern: {
                        value: /^([1-9]?[0-9]|100)$/,
                        message: '※ 0~100で入力してください',
                      },
                    })}
                    variant="standard"
                    fullWidth
                    id="interestRate"
                    label="金利"
                    autoComplete="interestRate"
                  />
                  {errors.interestRate && (
                    <span className={classes.errorMessage}>
                      {errors.interestRate.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={3} lg={2}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...register('debitInstitution')}
                    id="combo-box-demo"
                    options={debitInstitutionList}
                    getOptionLabel={(option) => option.name ?? ''}
                    getOptionSelected={(option) =>
                      option.id == debitInstitution.id
                    }
                    value={debitInstitution}
                    onChange={(_e, options) => {
                      setValue('debitInstitution', options?.id)
                      setDebitInstitution({
                        id: options?.id,
                        name: options?.name,
                      })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="口座振替先金融機関"
                        onKeyDown={(e) => {
                          if (e.code == 'Enter') {
                            setDebitInstitution({
                              name: (e.target as HTMLInputElement).value,
                            })
                          }
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...register('debitBranch')}
                    id="combo-box-demo"
                    options={debitBranchList}
                    getOptionLabel={(option) => option.name ?? ''}
                    getOptionSelected={(option) => option.id == debitBranch.id}
                    value={debitBranch}
                    onChange={(_e, options) => {
                      setValue('debitBranch', options?.id)
                      setDebitBranch({ id: options?.id, name: options?.name })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="口座振替先支店"
                        onKeyDown={(e) => {
                          if (e.code == 'Enter') {
                            setDebitBranch({
                              name: (e.target as HTMLInputElement).value,
                            })
                          }
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...register('documentStorageLocation')}
                    variant="standard"
                    fullWidth
                    id="documentStorageLocation"
                    label="資料格納先"
                    autoComplete="documentStorageLocation"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('financeHearing')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="financeHearing"
                  label="【融】ヒアリング"
                  autoComplete="financeHearing"
                  minRows={3}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('requestedDocument')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="requestedDocument"
                  label="依頼中の書類"
                  autoComplete="requestedDocument"
                  minRows={3}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('consultationContent')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="consultationContent"
                  label="相談内容"
                  autoComplete="consultationContent"
                  minRows={3}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('remark')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="remark"
                  label="備考"
                  autoComplete="remark"
                  minRows={3}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('remarkSecond')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="remarkSecond"
                  label="備考2"
                  autoComplete="remarkSecond"
                  minRows={3}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<LocalAtmIcon />}
        >
          請求
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('inSubject')}
                  variant="standard"
                  fullWidth
                  id="inSubject"
                  label="請求件名"
                  autoComplete="inSubject"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="inBillDate"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                      {...field}
                    >
                      <KeyboardDatePicker
                        label="請求日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('inBillDate', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="inPaymentDeadline"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="支払期限日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('inPaymentDeadline', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">送付タイプ</InputLabel>
                <Controller
                  name="inSendType"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12579)
                        .map((inSendType) =>
                          inSendType.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">請求フラグ</InputLabel>
                <Controller
                  name="inBillFlg"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12580)
                        .map((inBillFlg) =>
                          inBillFlg.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">入金状況</InputLabel>
                <Controller
                  name="inPaymentStatus"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} disabled>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12581)
                        .map((inPaymentStatus) =>
                          inPaymentStatus.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="inPaymentDay"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        disabled
                        label="入金日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('inPaymentDay', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('inOriginTransactionDeal')}
                  variant="standard"
                  fullWidth
                  id="inOriginTransactionDeal"
                  label="生成元取引ID"
                  autoComplete="inOriginTransactionDeal"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="paPaymentDeadline"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="支払期限日(SoLaboから)"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('paPaymentDeadline', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <TextField
                  {...register('paApprovalNumber')}
                  variant="standard"
                  fullWidth
                  id="paApprovalNumber"
                  label="稟議番号"
                  autoComplete="paApprovalNumber"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<GroupIcon />}
        >
          提携
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">サービス（親）</InputLabel>
                <Controller
                  name="alServiceParent"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12555)
                        .map((alServiceParent) =>
                          alServiceParent.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">サービス（子）</InputLabel>
                <Controller
                  name="alServiceChild"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.dealFields
                        .filter((v) => v.id == 12556)
                        .map((alServiceChild) =>
                          alServiceChild.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('alCompany')}
                  id="combo-box-demo"
                  options={alCompanyList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) => option.id == alCompany.id}
                  value={alCompany}
                  onChange={(_e, options) => {
                    setValue('alCompany', options?.id)
                    setAlCompany({ id: options?.id, name: options?.name })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="提携会社"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setAlCompany({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('alReferralCustomer')}
                  id="combo-box-demo"
                  options={alReferralCustomerList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == alReferralCustomer.id
                  }
                  value={alReferralCustomer}
                  onChange={(_e, options) => {
                    setValue('alReferralCustomer', options?.id)
                    setAlReferralCustomer({
                      id: options?.id,
                      name: options?.name,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="提携紹介顧客"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setAlReferralCustomer({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<ListAltIcon />}
        >
          早期経営改善計画
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={4} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('eaSupportCenter')}
                  id="combo-box-demo"
                  options={eaSupportCenterList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == eaSupportCenter.id
                  }
                  value={eaSupportCenter}
                  onChange={(_e, options) => {
                    setValue('eaSupportCenter', options?.id)
                    setEaSupportCenter({
                      id: options?.id,
                      name: options?.name,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="支援センター"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setEaSupportCenter({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('eaSupportCenterManager')}
                  id="combo-box-demo"
                  options={eaSupportCenterManagerList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == eaSupportCenterManager.id
                  }
                  value={eaSupportCenterManager}
                  onChange={(_e, options) => {
                    setValue('eaSupportCenterManager', options?.id)
                    setEaSupportCenterManager({
                      id: options?.id,
                      name: options?.name,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="支援センター担当者"
                      onKeyDown={(e) => {
                        if (e.code == 'Enter') {
                          setEaSupportCenterManager({
                            name: (e.target as HTMLInputElement).value,
                          })
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="eaApplicationDate"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="利用申請日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('eaApplicationDate', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* 組織 */}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.betweenButton}
          startIcon={<BusinessIcon />}
        >
          組織
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">オーナー</InputLabel>
                <Controller
                  name="orgOwnerId"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.ownerList
                        .filter((v) => v.active_flag == true)
                        .map((orgOwner) => (
                          <MenuItem key={orgOwner.id} value={orgOwner.id}>
                            {orgOwner.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('orgName', {
                  required: '※ 組織名を入力してください',
                })}
                margin="normal"
                fullWidth
                id="orgName"
                label={<span style={{ color: 'red' }}>組織名</span>}
                size="small"
              />
              {errors.orgName && (
                <span className={classes.errorMessage}>
                  {errors.orgName.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('representative', {
                  required: '※ 代表者名を入力してください',
                })}
                margin="normal"
                fullWidth
                id="representative"
                label={<span style={{ color: 'red' }}>代表者名</span>}
                size="small"
              />
              {errors.representative && (
                <span className={classes.errorMessage}>
                  {errors.representative.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('representativeKana', {
                  required: '※ 代表者名ふりがなを入力してください',
                  pattern: {
                    value: /^[ぁ-んー　]+$/,
                    message: '※ ふりがなのみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="representativeKana"
                label={<span style={{ color: 'red' }}>代表者名ふりがな</span>}
                size="small"
              />
              {errors.representativeKana && (
                <span className={classes.errorMessage}>
                  {errors.representativeKana.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('orgTel', {
                  required: '※ 電話番号を入力してください',
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '※ 電話番号形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="orgTel"
                label={<span style={{ color: 'red' }}>電話番号</span>}
                size="small"
              />
              {errors.orgTel && (
                <span className={classes.errorMessage}>
                  {errors.orgTel.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('orgTelSecond', {
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '※ 電話番号形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="orgTelSecond"
                label="電話番号2"
                size="small"
              />
              {errors.orgTelSecond && (
                <span className={classes.errorMessage}>
                  {errors.orgTelSecond.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('orgPostCode', {
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{4}$/,
                    message: '※ ハイフン入り(3-4)形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="orgPostCode"
                label="郵便番号"
                size="small"
                onBlur={(e) => setOrgAddressByPostCode(e.target.value)}
              />
              {errors.orgPostCode && (
                <span className={classes.errorMessage}>
                  {errors.orgPostCode.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('orgAddress', {
                  required: '※ 住所を入力してください',
                })}
                margin="normal"
                fullWidth
                id="orgAddress"
                label={<span style={{ color: 'red' }}>住所</span>}
                size="small"
                value={orgAddress}
                onChange={(e) => setOrgAddress(e.target.value)}
              />
              {errors.orgAddress && (
                <span className={classes.errorMessage}>
                  {errors.orgAddress.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('orgAddressBuildings')}
                margin="normal"
                fullWidth
                id="orgAddressBuildings"
                label="住所(ビル・マンション)"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  起業する業種（祖父母）
                </InputLabel>
                <Controller
                  name="industryGrandparent"
                  control={control}
                  rules={{
                    required: '※ 起業する業種（祖父母）を入力してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        setIndustryParentFormList(e.target.value as number)
                        setValue(
                          'industryGrandparent',
                          e.target.value as number
                        )
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {INDUSTRY_GRANDPARENT.map((industryGrandparent) => (
                        <MenuItem
                          key={industryGrandparent.pipedrive_field_id}
                          value={industryGrandparent.pipedrive_field_id}
                        >
                          {industryGrandparent.industry_grandparent_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.industryGrandparent && (
                <span className={classes.errorMessage}>
                  {errors.industryGrandparent.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  起業する業種（親）
                </InputLabel>
                <Controller
                  name="industryParent"
                  control={control}
                  rules={{
                    required: '※ 起業する業種（親）を入力してください',
                    min: {
                      value: 1,
                      message: '※ 起業する業種（親）を入力してください',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        setIndustryChildFormList(e.target.value as number)
                        setValue('industryParent', e.target.value as number)
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {industryParentList.map((industryParent) => (
                        <MenuItem
                          key={industryParent.pipedrive_field_id}
                          value={industryParent.pipedrive_field_id}
                        >
                          {industryParent.industry_parent_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.industryParent && (
                <span className={classes.errorMessage}>
                  {errors.industryParent.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  起業する業種（子）
                </InputLabel>
                <Controller
                  name="industryChild"
                  control={control}
                  rules={{
                    required: '※ 起業する業種（子）を入力してください',
                    min: {
                      value: 1,
                      message: '※ 起業する業種（子）を入力してください',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        setIndustryGrandchildFormList(e.target.value as number)
                        setValue('industryChild', e.target.value as number)
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {industryChildList.map((industryChild) => (
                        <MenuItem
                          key={industryChild.pipedrive_field_id}
                          value={industryChild.pipedrive_field_id}
                        >
                          {industryChild.industry_child_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.industryChild && (
                <span className={classes.errorMessage}>
                  {errors.industryChild.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  起業する業種（孫）
                </InputLabel>
                <Controller
                  name="industryGrandchild"
                  control={control}
                  rules={{
                    required: '※ 起業する業種（孫）を入力してください',
                    min: {
                      value: 1,
                      message: '※ 起業する業種（孫）を入力してください',
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        setValue('industryGrandchild', e.target.value as number)
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {industryGrandchildList.map((industryGrandchild) => (
                        <MenuItem
                          key={industryGrandchild.pipedrive_field_id}
                          value={industryGrandchild.pipedrive_field_id}
                        >
                          {industryGrandchild.industry_grandchild_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.industryGrandchild && (
                <span className={classes.errorMessage}>
                  {errors.industryGrandchild.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="openingTime"
                  control={control}
                  rules={{
                    required: '※ 開業時期を選択してください',
                  }}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label={<span style={{ color: 'red' }}>開業時期</span>}
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('openingTime', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
              {errors.openingTime && (
                <span className={classes.errorMessage}>
                  {errors.openingTime.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  {...register('openingPrefecture', {
                    required: '※ 開業予定都道府県を選択してください',
                  })}
                  id="combo-box-demo"
                  options={openingPrefectureList}
                  getOptionLabel={(option) => option.name ?? ''}
                  getOptionSelected={(option) =>
                    option.id == openingPrefecture.id
                  }
                  value={openingPrefecture}
                  onChange={(_e, options) => {
                    setValue('openingPrefecture', options?.id)
                    setOpeningPrefecture({
                      id: options?.id,
                      name: options?.name,
                    })
                    trigger('openingPrefecture')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span style={{ color: 'red' }}>開業予定都道府県</span>
                      }
                    />
                  )}
                />
              </FormControl>
              {errors.openingPrefecture && (
                <span className={classes.errorMessage}>
                  {errors.openingPrefecture.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  創業状況
                </InputLabel>
                <Controller
                  name="foundedStatus"
                  control={control}
                  rules={{
                    required: '※ 創業状況を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger([
                          'firstBeforeSalesAmount',
                          'firstBeforeNetIncome',
                          'firstBeforeOfficerCompensationAmount',
                          'firstBeforeDepreciationAmount',
                          'secondBeforeSalesAmount',
                          'secondBeforeNetIncome',
                          'secondBeforeOfficerCompensationAmount',
                          'secondBeforeDepreciationAmount',
                        ])
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4045)
                        .map((foundedStatus) =>
                          foundedStatus.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.foundedStatus && (
                <span className={classes.errorMessage}>
                  {errors.foundedStatus.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">ポスコロ提案</InputLabel>
                <Controller
                  name="eaPostCoronaPropose"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4094)
                        .map((eaPostCoronaPropose) =>
                          eaPostCoronaPropose.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="foundedDate"
                  control={control}
                  rules={{
                    required: '※ 創業年月を選択してください',
                  }}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label={<span style={{ color: 'red' }}>創業年月</span>}
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('foundedDate', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
              {errors.foundedDate && (
                <span className={classes.errorMessage}>
                  {errors.foundedDate.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  起業形態
                </InputLabel>
                <Controller
                  name="startupType"
                  control={control}
                  rules={{
                    required: '※ 起業形態を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4055)
                        .map((startupType) =>
                          startupType.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.startupType && (
                <span className={classes.errorMessage}>
                  {errors.startupType.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  決算月
                </InputLabel>
                <Controller
                  name="closingMonth"
                  control={control}
                  rules={{
                    required: '※ 決算月を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4084)
                        .map((closingMonth) =>
                          closingMonth.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.closingMonth && (
                <span className={classes.errorMessage}>
                  {errors.closingMonth.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  事務所
                </InputLabel>
                <Controller
                  name="office"
                  control={control}
                  rules={{
                    required: '※ 事務所を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4044)
                        .map((office) =>
                          office.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.office && (
                <span className={classes.errorMessage}>
                  {errors.office.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('staffNumber', {
                  required: '※ 従業員数を入力してください',
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="staffNumber"
                label={<span style={{ color: 'red' }}>従業員数</span>}
                size="small"
              />
              {errors.staffNumber && (
                <span className={classes.errorMessage}>
                  {errors.staffNumber.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<MoneyOffIcon />}
        >
          借入
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  既存借入1(事業用融資)
                </InputLabel>
                <Controller
                  name="existingLoanFirst"
                  control={control}
                  rules={{
                    required: '※ 既存借入金額1(事業用融資)を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger([
                          'existingLoanAmountFirst',
                          'existingLoanDetailFirst',
                        ])
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4046)
                        .map((existingLoanFirst) =>
                          existingLoanFirst.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.existingLoanFirst && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanFirst.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <TextField
                {...register('existingLoanAmountFirst', {
                  required: ['2925', '2926', '2927', '2928'].includes(
                    watch('existingLoanFirst')
                  )
                    ? '※ 既存借入金額1(事業用融資)を入力してください'
                    : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="existingLoanAmountFirst"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'existingLoanAmountFirst',
                      numberFormat(Number(amount))
                    )
                  }
                }}
                label={
                  <span
                    style={
                      ['2925', '2926', '2927', '2928'].includes(
                        watch('existingLoanFirst')
                      )
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    既存借入金額1(事業用融資)
                  </span>
                }
                size="small"
              />
              {errors.existingLoanAmountFirst && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanAmountFirst.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('existingLoanDetailFirst', {
                  required: ['2925', '2926', '2927', '2928'].includes(
                    watch('existingLoanFirst')
                  )
                    ? '※ 既存借入詳細1(事業用融資)を入力してください'
                    : false,
                })}
                margin="normal"
                fullWidth
                id="existingLoanDetailFirst"
                label={
                  <span
                    style={
                      ['2925', '2926', '2927', '2928'].includes(
                        watch('existingLoanFirst')
                      )
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    既存借入詳細1(事業用融資)
                  </span>
                }
                size="small"
              />
              {errors.existingLoanDetailFirst && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanDetailFirst.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  既存借入2(事業用融資)
                </InputLabel>
                <Controller
                  name="existingLoanSecond"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4049)
                        .map((existingLoanSecond) =>
                          existingLoanSecond.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <TextField
                {...register('existingLoanAmountSecond', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="existingLoanAmountSecond"
                label="既存借入金額2(事業用融資)"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'existingLoanAmountSecond',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.existingLoanAmountSecond && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanAmountSecond.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('existingLoanDetailSecond')}
                margin="normal"
                fullWidth
                id="existingLoanDetailSecond"
                label="既存借入詳細2(事業用融資)"
                size="small"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  既存借入3(事業用融資)
                </InputLabel>
                <Controller
                  name="existingLoanThird"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4052)
                        .map((existingLoanThird) =>
                          existingLoanThird.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <TextField
                {...register('existingLoanAmountThird', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="existingLoanAmountThird"
                label="既存借入金額3(事業用融資)"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'existingLoanAmountThird',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.existingLoanAmountThird && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanAmountThird.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('existingLoanDetailThird')}
                margin="normal"
                fullWidth
                id="existingLoanDetailThird"
                label="既存借入詳細3(事業用融資)"
                size="small"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  既存借入4(事業用融資)
                </InputLabel>
                <Controller
                  name="existingLoanForth"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4067)
                        .map((existingLoanForth) =>
                          existingLoanForth.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <TextField
                {...register('existingLoanAmountForth', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="existingLoanAmountForth"
                label="既存借入金額4(事業用融資)"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'existingLoanAmountForth',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.existingLoanAmountForth && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanAmountForth.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('existingLoanDetailForth')}
                margin="normal"
                fullWidth
                id="existingLoanDetailForth"
                label="既存借入詳細4(事業用融資)"
                size="small"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  既存借入5(事業用融資)
                </InputLabel>
                <Controller
                  name="existingLoanFifth"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.orgFields
                        .filter((v) => v.id == 4068)
                        .map((existingLoanFifth) =>
                          existingLoanFifth.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <TextField
                {...register('existingLoanAmountFifth', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="existingLoanAmountFifth"
                label="既存借入金額5(事業用融資)"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'existingLoanAmountFifth',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.existingLoanAmountFifth && (
                <span className={classes.errorMessage}>
                  {errors.existingLoanAmountFifth.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('existingLoanDetailFifth')}
                margin="normal"
                fullWidth
                id="existingLoanDetailFifth"
                label="既存借入詳細5(事業用融資)"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<TimelineIcon />}
        >
          売上
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('firstBeforeSalesAmount', {
                  required: ['2922', '2923'].includes(watch('foundedStatus'))
                    ? '※ 【前期】売上高を入力してください'
                    : false,
                  pattern: {
                    value: /^-?[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="firstBeforeSalesAmount"
                label={
                  <span
                    style={
                      ['2922', '2923'].includes(watch('foundedStatus'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    【前期】売上高
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'firstBeforeSalesAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.firstBeforeSalesAmount && (
                <span className={classes.errorMessage}>
                  {errors.firstBeforeSalesAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('firstBeforeNetIncome', {
                  required: ['2922', '2923'].includes(watch('foundedStatus'))
                    ? '※ 【前期】当期純利益(法人),所得金額(個人)を入力してください'
                    : false,
                  pattern: {
                    value: /^-?[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="firstBeforeNetIncome"
                label={
                  <span
                    style={
                      ['2922', '2923'].includes(watch('foundedStatus'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    【前期】当期純利益(法人),所得金額(個人)
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'firstBeforeNetIncome',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.firstBeforeNetIncome && (
                <span className={classes.errorMessage}>
                  {errors.firstBeforeNetIncome.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('firstBeforeOfficerCompensationAmount', {
                  required: ['2922', '2923'].includes(watch('foundedStatus'))
                    ? '※ 【前期】役員報酬(法人)を入力してください'
                    : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="firstBeforeOfficerCompensationAmount"
                label={
                  <span
                    style={
                      ['2922', '2923'].includes(watch('foundedStatus'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    【前期】役員報酬(法人)
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'firstBeforeOfficerCompensationAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.firstBeforeOfficerCompensationAmount && (
                <span className={classes.errorMessage}>
                  {errors.firstBeforeOfficerCompensationAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('firstBeforeDepreciationAmount', {
                  required: ['2922', '2923'].includes(watch('foundedStatus'))
                    ? '※ 【前期】減価償却売上高を入力してください'
                    : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="firstBeforeDepreciationAmount"
                label={
                  <span
                    style={
                      ['2922', '2923'].includes(watch('foundedStatus'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    【前期】減価償却
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'firstBeforeDepreciationAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.firstBeforeDepreciationAmount && (
                <span className={classes.errorMessage}>
                  {errors.firstBeforeDepreciationAmount.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('secondBeforeSalesAmount', {
                  required:
                    watch('foundedStatus') == '2923'
                      ? '※ 【前々期】売上高を入力してください'
                      : false,
                  pattern: {
                    value: /^-?[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="secondBeforeSalesAmount"
                label={
                  <span
                    style={
                      watch('foundedStatus') == '2923' ? { color: 'red' } : {}
                    }
                  >
                    【前々期】売上高
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'secondBeforeSalesAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.secondBeforeSalesAmount && (
                <span className={classes.errorMessage}>
                  {errors.secondBeforeSalesAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('secondBeforeNetIncome', {
                  required:
                    watch('foundedStatus') == '2923'
                      ? '※ 【前々期】当期純利益(法人),所得金額(個人)を入力してください'
                      : false,
                  pattern: {
                    value: /^-?[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="secondBeforeNetIncome"
                label={
                  <span
                    style={
                      watch('foundedStatus') == '2923' ? { color: 'red' } : {}
                    }
                  >
                    【前々期】当期純利益(法人),所得金額(個人)
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'secondBeforeNetIncome',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.secondBeforeNetIncome && (
                <span className={classes.errorMessage}>
                  {errors.secondBeforeNetIncome.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('secondBeforeOfficerCompensationAmount', {
                  required:
                    watch('foundedStatus') == '2923'
                      ? '※ 【前々期】役員報酬(法人)を入力してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="secondBeforeOfficerCompensationAmount"
                label={
                  <span
                    style={
                      watch('foundedStatus') == '2923' ? { color: 'red' } : {}
                    }
                  >
                    【前々期】役員報酬(法人)
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'secondBeforeOfficerCompensationAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.secondBeforeOfficerCompensationAmount && (
                <span className={classes.errorMessage}>
                  {errors.secondBeforeOfficerCompensationAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('secondBeforeDepreciationAmount', {
                  required:
                    watch('foundedStatus') == '2923'
                      ? '※ 【前々期】減価償却を入力してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="secondBeforeDepreciationAmount"
                label={
                  <span
                    style={
                      watch('foundedStatus') == '2923' ? { color: 'red' } : {}
                    }
                  >
                    【前々期】減価償却
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'secondBeforeDepreciationAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.secondBeforeDepreciationAmount && (
                <span className={classes.errorMessage}>
                  {errors.secondBeforeDepreciationAmount.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...register('orgRemark')}
                multiline
                variant="outlined"
                fullWidth
                id="orgRemark"
                label="備考"
                autoComplete="orgRemark"
                minRows={3}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.betweenButton}
          startIcon={<PersonIcon />}
        >
          人物
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.betweenButton}
          onClick={copyMonthUrl}
          startIcon={<LinkIcon />}
        >
          資金調達情報配信サービス(月額)
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.betweenButton}
          onClick={copyYearUrl}
          startIcon={<LinkIcon />}
        >
          資金調達情報配信サービス(年額)
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">オーナー</InputLabel>
                <Controller
                  name="personOwnerId"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.ownerList
                        .filter((v) => v.active_flag == true)
                        .map((personOwner) => (
                          <MenuItem key={personOwner.id} value={personOwner.id}>
                            {personOwner.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('personName', {
                  required: '※ 顧客名を入力してください',
                })}
                margin="normal"
                fullWidth
                id="personName"
                label={<span style={{ color: 'red' }}>顧客名</span>}
                size="small"
              />
              {errors.personName && (
                <span className={classes.errorMessage}>
                  {errors.personName.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('kana', {
                  pattern: {
                    value: /^[ぁ-んー　]+$/,
                    message: '※ ふりがなのみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="kana"
                label="ふりがな"
                size="small"
              />
              {errors.kana && (
                <span className={classes.errorMessage}>
                  {errors.kana.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('personTel', {
                  required: '※ 電話番号を入力してください',
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '※ 電話番号形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="personTel"
                label={<span style={{ color: 'red' }}>電話番号</span>}
                size="small"
              />
              {errors.personTel && (
                <span className={classes.errorMessage}>
                  {errors.personTel.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('personTelSecond', {
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '※ 電話番号形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="personTelSecond"
                label="電話番号2"
                size="small"
              />
              {errors.personTelSecond && (
                <span className={classes.errorMessage}>
                  {errors.personTelSecond.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <TextField
                {...register('personEmail', {
                  required: '※ メールアドレスを入力してください',
                  pattern: {
                    // 一般的なメールアドレスのパターン
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
                    message: '※ 正しいメールアドレス形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="personEmail"
                label="電子メール"
                size="small"
              />
              {errors.personEmail && (
                <span className={classes.errorMessage}>
                  {errors.personEmail.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                {...register('personPostCode', {
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{4}$/,
                    message: '※ ハイフン入り(3-4)形式で入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="personPostCode"
                label="郵便番号"
                size="small"
                onBlur={(e) => setPersonAddressByPostCode(e.target.value)}
              />
              {errors.personPostCode && (
                <span className={classes.errorMessage}>
                  {errors.personPostCode.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('personAddress')}
                margin="normal"
                fullWidth
                id="personAddress"
                label="住所"
                size="small"
                value={personAddress}
                onChange={(e) => setPersonAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                {...register('personAddressBuildings')}
                margin="normal"
                fullWidth
                id="personAddressBuildings"
                label="住所(ビル・マンション等)"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  性別
                </InputLabel>
                <Controller
                  name="sex"
                  control={control}
                  rules={{
                    required: '※ 性別を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9069)
                        .map((sex) =>
                          sex.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="生年月日"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('birthday', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  業界経験
                </InputLabel>
                <Controller
                  name="industryExperience"
                  control={control}
                  rules={{
                    required: '※ 業界経験を選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9133)
                        .map((industryExperience) =>
                          industryExperience.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('industryExperienceYears', {
                  required: '※ 業界経験年数を入力してください',
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="industryExperienceYears"
                label={<span style={{ color: 'red' }}>業界経験年数</span>}
                size="small"
              />
              {errors.industryExperienceYears && (
                <span className={classes.errorMessage}>
                  {errors.industryExperienceYears.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('resource', {
                  required: '※ 自己資金を入力してください',
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="resource"
                label={<span style={{ color: 'red' }}>自己資金</span>}
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('resource', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.resource && (
                <span className={classes.errorMessage}>
                  {errors.resource.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('otherResource', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="otherResource"
                label="その他の事業資金準備"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('otherResource', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.otherResource && (
                <span className={classes.errorMessage}>
                  {errors.otherResource.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  税理士顧問契約
                </InputLabel>
                <Controller
                  name="taxAdvisoryContract"
                  control={control}
                  rules={{
                    required: '※ 税理士顧問契約を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('taxAdvisoryContractAmount')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9149)
                        .map((taxAdvisoryContract) =>
                          taxAdvisoryContract.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.taxAdvisoryContract && (
                <span className={classes.errorMessage}>
                  {errors.taxAdvisoryContract.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('taxAdvisoryContractAmount', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="taxAdvisoryContractAmount"
                label="税理士顧問料"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'taxAdvisoryContractAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.taxAdvisoryContractAmount && (
                <span className={classes.errorMessage}>
                  {errors.taxAdvisoryContractAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  SoLaboからのメール受信
                </InputLabel>
                <Controller
                  name="mailSend"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9143)
                        .map((mailSend) =>
                          mailSend.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">顧客ランク</InputLabel>
                <Controller
                  name="customerRank"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9145)
                        .map((customerRank) =>
                          customerRank.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">
                  資金調達情報配信サービス提案
                </InputLabel>
                <Controller
                  name="financingInfo"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9154)
                        .map((financingInfo) =>
                          financingInfo.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<EqualizerIcon />}
        >
          ローン
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  車ローン
                </InputLabel>
                <Controller
                  name="carLoan"
                  control={control}
                  rules={{
                    required: '※ 車ローンを選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9084)
                        .map((carLoan) =>
                          carLoan.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.carLoan && (
                <span className={classes.errorMessage}>
                  {errors.carLoan.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  カードローン
                </InputLabel>
                <Controller
                  name="cardLoan"
                  control={control}
                  rules={{
                    required: '※ カードローンを選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('cardLoanAmount')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9147)
                        .map((cardLoan) =>
                          cardLoan.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.cardLoan && (
                <span className={classes.errorMessage}>
                  {errors.cardLoan.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('cardLoanAmount', {
                  required:
                    watch('cardLoan') == '6024'
                      ? '※ カードローン金額を入力してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="cardLoanAmount"
                label={
                  <span
                    style={watch('cardLoan') == '6024' ? { color: 'red' } : {}}
                  >
                    カードローン金額
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('cardLoanAmount', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.cardLoanAmount && (
                <span className={classes.errorMessage}>
                  {errors.cardLoanAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  住宅ローン
                </InputLabel>
                <Controller
                  name="housingLoan"
                  control={control}
                  rules={{
                    required: '※ 住宅ローンを選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('housingBalance')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9085)
                        .map((housingLoan) =>
                          housingLoan.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.housingLoan && (
                <span className={classes.errorMessage}>
                  {errors.housingLoan.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="housingBuyTime"
                  control={control}
                  render={({ field }) => (
                    <MuiPickersUtilsProvider
                      utils={ExtendedUtils}
                      locale={jaLocale}
                    >
                      <KeyboardDatePicker
                        {...field}
                        label="住宅購入時期"
                        value={field.value}
                        format="yyyy-MM-dd"
                        autoOk={true}
                        variant="inline"
                        onChange={(_date, v) => {
                          setValue('housingBuyTime', v)
                        }}
                        invalidDateMessage="※ フォーマットに従って入力してください"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('monthlyRepaymentAmountOfHousing', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="monthlyRepaymentAmountOfHousing"
                label="住宅月額返済額"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'monthlyRepaymentAmountOfHousing',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.monthlyRepaymentAmountOfHousing && (
                <span className={classes.errorMessage}>
                  {errors.monthlyRepaymentAmountOfHousing.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('remainingRepaymentPeriodForHousing', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="remainingRepaymentPeriodForHousing"
                label="住宅残り返済期間"
                size="small"
              />
              {errors.remainingRepaymentPeriodForHousing && (
                <span className={classes.errorMessage}>
                  {errors.remainingRepaymentPeriodForHousing.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('housingBalance', {
                  required:
                    watch('housingLoan') == '138'
                      ? '※ 住宅残債を入力してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="housingBalance"
                label={
                  <span
                    style={
                      watch('housingLoan') == '138' ? { color: 'red' } : {}
                    }
                  >
                    住宅残債
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('housingBalance', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.housingBalance && (
                <span className={classes.errorMessage}>
                  {errors.housingBalance.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<MoneyOffIcon />}
        >
          借入
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  借入(個人)
                </InputLabel>
                <Controller
                  name="borrowing"
                  control={control}
                  rules={{
                    required: '※ 借入(個人)を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger(['borrowingAmount', 'borrowingDetail'])
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9093)
                        .map((borrowing) =>
                          borrowing.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.borrowing && (
                <span className={classes.errorMessage}>
                  {errors.borrowing.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('borrowingAmount', {
                  required:
                    watch('borrowing') == '142'
                      ? '※ 借入金額(個人)を入力してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="borrowingAmount"
                label={
                  <span
                    style={watch('borrowing') == '142' ? { color: 'red' } : {}}
                  >
                    借入金額(個人)
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('borrowingAmount', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.borrowingAmount && (
                <span className={classes.errorMessage}>
                  {errors.borrowingAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={8} lg={3}>
              <TextField
                {...register('borrowingDetail', {
                  required:
                    watch('borrowing') == '142'
                      ? '※ 借入詳細(個人)を入力してください'
                      : false,
                })}
                margin="normal"
                fullWidth
                id="borrowingDetail"
                label={
                  <span
                    style={watch('borrowing') == '142' ? { color: 'red' } : {}}
                  >
                    借入詳細(個人)
                  </span>
                }
                size="small"
              />
              {errors.borrowingDetail && (
                <span className={classes.errorMessage}>
                  {errors.borrowingDetail.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  過去に自己破産・債務整理経験
                </InputLabel>
                <Controller
                  name="selfBankruptcy"
                  control={control}
                  rules={{
                    required:
                      '※ 過去に自己破産・債務整理経験を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('selfBankruptcyDetails')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9098)
                        .map((selfBankruptcy) =>
                          selfBankruptcy.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.selfBankruptcy && (
                <span className={classes.errorMessage}>
                  {errors.selfBankruptcy.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('selfBankruptcyAmount', {
                  required: ['150', '151'].includes(watch('selfBankruptcy'))
                    ? '※ 過去に自己破産・債務整理経験金額を入力してください'
                    : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="selfBankruptcyAmount"
                label={
                  <span
                    style={
                      ['150', '151'].includes(watch('selfBankruptcy'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    過去に自己破産・債務整理経験金額
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'selfBankruptcyAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.selfBankruptcyAmount && (
                <span className={classes.errorMessage}>
                  {errors.selfBankruptcyAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={10} lg={3}>
              <TextField
                {...register('selfBankruptcyDetails', {
                  required: ['150', '151'].includes(watch('selfBankruptcy'))
                    ? '※ 過去に自己破産・債務整理経験詳細を入力してください'
                    : false,
                })}
                margin="normal"
                fullWidth
                id="selfBankruptcyDetails"
                label={
                  <span
                    style={
                      ['150', '151'].includes(watch('selfBankruptcy'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    過去に自己破産・債務整理経験詳細
                  </span>
                }
                size="small"
              />
              {errors.selfBankruptcyDetails && (
                <span className={classes.errorMessage}>
                  {errors.selfBankruptcyDetails.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  借入支払遅延
                </InputLabel>
                <Controller
                  name="borrowingPaymentDelay"
                  control={control}
                  rules={{
                    required: '※ 借入支払遅延を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('borrowingPaymentDelay')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9092)
                        .map((borrowingPaymentDelay) =>
                          borrowingPaymentDelay.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.borrowingPaymentDelay && (
                <span className={classes.errorMessage}>
                  {errors.borrowingPaymentDelay.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={10} lg={3}>
              <TextField
                {...register('borrowingPaymentDelayDetail', {
                  required:
                    watch('borrowingPaymentDelay') == '140'
                      ? '※ 借入支払遅延詳細を入力してください'
                      : false,
                })}
                margin="normal"
                fullWidth
                id="borrowingPaymentDelayDetail"
                label={
                  <span
                    style={
                      watch('borrowingPaymentDelay') == '140'
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    借入支払遅延詳細
                  </span>
                }
                size="small"
              />
              {errors.borrowingPaymentDelayDetail && (
                <span className={classes.errorMessage}>
                  {errors.borrowingPaymentDelayDetail.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  公共料金支払遅延
                </InputLabel>
                <Controller
                  name="delayedUtilityBillPayment"
                  control={control}
                  rules={{
                    required: '※ 公共料金支払遅延を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('delayedUtilityBillPaymentDetail')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9094)
                        .map((delayedUtilityBillPayment) =>
                          delayedUtilityBillPayment.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.delayedUtilityBillPayment && (
                <span className={classes.errorMessage}>
                  {errors.delayedUtilityBillPayment.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={10} lg={3}>
              <TextField
                {...register('delayedUtilityBillPaymentDetail', {
                  required:
                    watch('delayedUtilityBillPayment') == '144'
                      ? '※ 公共料金支払遅延詳細を入力してください'
                      : false,
                })}
                margin="normal"
                fullWidth
                id="delayedUtilityBillPaymentDetail"
                label={
                  <span
                    style={
                      watch('delayedUtilityBillPayment') == '144'
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    公共料金支払遅延詳細
                  </span>
                }
                size="small"
              />
              {errors.delayedUtilityBillPaymentDetail && (
                <span className={classes.errorMessage}>
                  {errors.delayedUtilityBillPaymentDetail.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  税金滞納
                </InputLabel>
                <Controller
                  name="delayedTax"
                  control={control}
                  rules={{
                    required: '※ 税金滞納を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('delayedTaxDetail')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9096)
                        .map((delayedTax) =>
                          delayedTax.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.delayedTax && (
                <span className={classes.errorMessage}>
                  {errors.delayedTax.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={10} lg={3}>
              <TextField
                {...register('delayedTaxDetail', {
                  required: ['146', '148'].includes(watch('delayedTax'))
                    ? '※ 税金滞納詳細を入力してください'
                    : false,
                })}
                margin="normal"
                fullWidth
                id="delayedTaxDetail"
                label={
                  <span
                    style={
                      ['146', '148'].includes(watch('delayedTax'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    税金滞納詳細
                  </span>
                }
                size="small"
              />
              {errors.delayedTaxDetail && (
                <span className={classes.errorMessage}>
                  {errors.delayedTaxDetail.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<WcIcon />}
        >
          配偶者
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  配偶者
                </InputLabel>
                <Controller
                  name="spouse"
                  control={control}
                  rules={{
                    required: '※ 配偶者を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger(['spouseIndustry', 'spouseSalary'])
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9067)
                        .map((spouse) =>
                          spouse.options.map((e) => (
                            <MenuItem key={e.id} value={String(e.id)}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.spouse && (
                <span className={classes.errorMessage}>
                  {errors.spouse.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="owner-select-label"
                  style={
                    ['98', '152'].includes(watch('spouse'))
                      ? { color: 'red' }
                      : {}
                  }
                >
                  配偶者業種
                </InputLabel>
                <Controller
                  name="spouseIndustry"
                  control={control}
                  rules={{
                    required: ['98', '152'].includes(watch('spouse'))
                      ? '※ 配偶者業種を選択してください'
                      : false,
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9068)
                        .map((spouseIndustry) =>
                          spouseIndustry.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.spouseIndustry && (
                <span className={classes.errorMessage}>
                  {errors.spouseIndustry.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('spouseSalary', {
                  required: ['98', '152'].includes(watch('spouse'))
                    ? '※ 配偶者給与を入力してください'
                    : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="spouseSalary"
                label={
                  <span
                    style={
                      ['98', '152'].includes(watch('spouse'))
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    配偶者給与
                  </span>
                }
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('spouseSalary', numberFormat(Number(amount)))
                  }
                }}
              />
              {errors.spouseSalary && (
                <span className={classes.errorMessage}>
                  {errors.spouseSalary.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButton}
          startIcon={<LocalHospitalIcon />}
        >
          保険
        </Button>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  積立保険
                </InputLabel>
                <Controller
                  name="insurance"
                  control={control}
                  rules={{
                    required: '※ 積立保険を選択してください',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={async (e) => {
                        await field.onChange(e)
                        trigger('insuranceCompany')
                      }}
                    >
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9101)
                        .map((insurance) =>
                          insurance.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.insurance && (
                <span className={classes.errorMessage}>
                  {errors.insurance.message}
                </span>
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <TextField
                {...register('insuranceCompany', {
                  required:
                    watch('insurance') == '154'
                      ? '※ 積立保険社名を入力してください'
                      : false,
                })}
                margin="normal"
                fullWidth
                id="insuranceCompany"
                label={
                  <span
                    style={watch('insurance') == '154' ? { color: 'red' } : {}}
                  >
                    積立保険社名
                  </span>
                }
                size="small"
              />
              {errors.insuranceCompany && (
                <span className={classes.errorMessage}>
                  {errors.insuranceCompany.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('remainingRepaymentPeriodForInsurance', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="remainingRepaymentPeriodForInsurance"
                label="積立保険残り返済期間"
                size="small"
              />
              {errors.remainingRepaymentPeriodForInsurance && (
                <span className={classes.errorMessage}>
                  {errors.remainingRepaymentPeriodForInsurance.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <TextField
                {...register('insuranceCancellationRefundAmount', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="insuranceCancellationRefundAmount"
                label="積立保険解約返戻金額"
                size="small"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue(
                      'insuranceCancellationRefundAmount',
                      numberFormat(Number(amount))
                    )
                  }
                }}
              />
              {errors.insuranceCancellationRefundAmount && (
                <span className={classes.errorMessage}>
                  {errors.insuranceCancellationRefundAmount.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={2} lg={1}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                  賃貸/持ち家
                </InputLabel>
                <Controller
                  name="rentOrHouse"
                  control={control}
                  rules={{
                    required: '※ 賃貸/持ち家をを選択してください',
                  }}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {pipedrive.personFields
                        .filter((v) => v.id == 9105)
                        .map((rentOrHouse) =>
                          rentOrHouse.options.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.rentOrHouse && (
                <span className={classes.errorMessage}>
                  {errors.rentOrHouse.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...register('personRemark')}
                multiline
                variant="outlined"
                fullWidth
                id="personRemark"
                label="備考"
                autoComplete="personRemark"
                minRows={3}
              />
            </Grid>
          </Grid>
          <Box component="span" m={3} className={classes.buttonWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
              startIcon={<Create />}
              onClick={() => unregister(['lostReason'])}
            >
              登録
            </Button>
          </Box>
        </Grid>
        <Snackbar
          open={snackBar.open}
          onClose={handleSnackBarClose}
          autoHideDuration={3000}
        >
          <Alert severity={snackBar.severity} onClose={handleSnackBarClose}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </form>
    </>
  )
}

export default FinanceDisplay
