import client from './client'

import { DealApi } from '../models/deal'
import { DealFields, FreeDialFields } from 'models/dealFields'
import { addHours, format } from 'date-fns'
import {
  FINANCE_SUPPORT_MEMBER,
  INDUSTRY_GRANDPARENT,
  PREFECTURE,
} from 'components/modules/Const'
import back from './back'

export const getPipelineId = (type: string): number => {
  switch (type) {
    case 'finance':
      return 2
    case 'subsidy':
      return 4
    case 'postCorona':
      return 14
    default:
      return 1
  }
}

function getStageId(owner): number {
  if (owner.type === 'finance') {
    return owner.id == 12620110 || owner.id == 12620286 ? 7 : 9
  } else if (owner.type === 'subsidy') {
    return 64
  } else if (owner.type === 'postCorona') {
    return 87
  } else {
    return 1
  }
}

// 取引一覧情報を取得
export const getDeals = async (
  userId: number,
  statusName: string
): Promise<DealApi[]> => {
  return client
    .get(`deals?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`, {
      params: {
        user_id: userId,
        status: statusName,
        sort: 'update_time DESC',
        limit: 500,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

// 単一の取引情報を取得
export const getDealById = async (id: string | undefined): Promise<DealApi> => {
  return client
    .get(`deals/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      // 識別子が多いためオブジェクトに置き換える
      const deal = {
        ...res.data,
        st_founding_status:
          res.data['0ebc9dc5cf0d16be355c7d28a4fa5ad69eb93531'],
        remark_second: res.data['0fd1ec0af3f72840103fbb14b62a1af8c861bee4'],
        fi_inquiry_route: res.data['01c8deb691ec8333873492217a38a43caf17ec8b'],
        fi_finance_interview_time:
          res.data['1f06a882cd39b2c97c916f714859a7464d9a059c'],
        pr_business_negotiation_date:
          res.data['02d4b3d3121dab78689f55f1f73af3f76f20002f'],
        document_storage_location:
          res.data['2c30d9f0d2073d080cad039ff2ed13587b9ab465'],
        pr_referral_fee: res.data['4b24900995863cb80a1501b391f88aeff7b6dde4'],
        fi_desired_amount_to:
          res.data['4f38aaf746058b6bcf2ca1608a1a6489426d591c'],
        st_sex: res.data['4ff57bb9b51cd6ca95eee4106cd32136c1001355'],
        in_bill_flg: res.data['5b26d0dadfddb370c0d275d18b75cd41bf819a3a'],
        fi_interview_type: res.data['6b798b37d97d46d965cbc1de473f7f921476ca99'],
        manager: res.data['6cf165d55b4d0958c2ac622bd560b09d2d321649'],
        in_subject: res.data['8d3a806c4006b869d5d8ef5722bbd27ddca69424'],
        fi_referral_source:
          res.data['9dd893760da629ef7bfe921ceaa88b01c8768601'],
        qu_how_know: res.data['24d69bcae2316f34be6731fe3b94d9dedce067b1'],
        fi_finance_interview_date:
          res.data['35c09166609bb95dd2f653ac6a22791cd00f0039'],
        st_prefecture: res.data['39ebf2c9a5251aa6229cc0a5171a8eb1410c424a'],
        in_send_type: res.data['42bbd510fd9fd276d7697bd60a3b0eb74fbcc22b'],
        ac_plan_fee: res.data['44aa887f857a803eefd72f5569f4503bbfeda4a2'],
        fi_desired_repayment_day:
          res.data['46b091ca8f1d238709542f7c9a03f5b3935bd812'],
        su_document_submit_date:
          res.data['60deb1bf7fd7870bb527a35d6bf51f792b7f1644'],
        in_origin_transaction_deal:
          res.data['062c249dd76ac83c9ca56bdeaaffeb6aa13c131f'],
        old_manager: res.data['94be9399d59da403942ef320c2b273c92cf1d758'],
        fi_desired_deferment_period:
          res.data['239ef96662c97835119b7d2526db7c160df8b17c'],
        pa_approval_number:
          res.data['615d40bdc49464b0f7dfd56b431784911b437552'],
        fi_debit_institution:
          res.data['724b1417b6390bb6a5ad981ca4d016d79b7f6dff'],
        ea_application_date:
          res.data['902cbcb2c9db47382249301b863616dca515be0c'],
        subscription: res.data['995aed0bbd9c9a97171a9edfe39b726bf63f9530'],
        in_bill_date: res.data['998fb56fd63eeffb1213a3cd3413f63b21fffcf8'],
        su_adopt_date: res.data['2138c4052a351b67671b5a4caa91f3fda24153c0'],
        ea_support_center: res.data['4609d93564a1a5ff7c7662dee7a57fe3f4a1ea2f'],
        fi_desired_date: res.data['5325fdb1bd8165202176ed85a02fd4ff63436bef'],
        finance_hearing: res.data['490b3bfef3791af7730a739eb204b38980f1e6ba'],
        consultation_content:
          res.data['8186a9f01bacc95a698a2d71130a650bcd2ac2c6'],
        fi_interest_rate: res.data['77636fe40481a76b943b04da6490701303549d1b'],
        al_service_child: res.data['94300d29a7b3819eaa14c3ff727f97cfe45de72d'],
        fi_requested_document:
          res.data['113376ae968c477de01bc31143d043b5c385d21f'],
        qu_hope_move_season:
          res.data['06756327a7ae244171befdd30b8741989c994413'],
        fi_debit_branch: res.data['96976366eeee865125668489cb1cc5530496c17f'],
        in_payment_day: res.data['02561687523a48edd224095dea1bf7b04803ca5d'],
        fi_decided_amount: res.data['2758653158cf0395f5fb67b11bd8b5aa282ce716'],
        institution: res.data['5383648224ba960a8cd628623ddc605bd486f2a5'],
        fi_contract_target_month:
          res.data['96101668961ac37d7bd4b86b9d7634ef2f8a1138'],
        fi_referral_source_manager:
          res.data['a0aa23d4afd98bd9a6015523f7c1a47b464ec485'],
        su_decided_amount: res.data['a4f6c3d6f4d589ddfcc30a909af0f279ba0bd194'],
        branch: res.data['a55c6719d71ef790698f46d505f3987d10830b76'],
        al_service_parent: res.data['a67f4de28688f8a1610c3a9374f621c6a27645ba'],
        institution_manager:
          res.data['a123eb6f2704aed6781ac3761df787c97662cd81'],
        al_company: res.data['a724568d49134d0f3d9b5d9f923195cbdeb8f789'],
        in_payment_status: res.data['aaaf15dbee89bc4f02cc1901ffef7801795a7855'],
        pr_business_negotiation_time:
          res.data['af1c84f3514d6a550f8eb67ea4d008f9a504d447'],
        in_payment_deadline:
          res.data['afa4acf94f1042675bd180f707996d6847aaccb9'],
        zoom_url: res.data['b5a5fbd79414538c82158943c77f9d4751a53fa0'],
        pa_payment_deadline:
          res.data['befab0d3786f48301b6428156ba9eba20d5633db'],
        ac_contract_period:
          res.data['c4b94baf74c3bb991303cab02fbfc07abf93b941'],
        wo_production: res.data['c9c9cf092f9ec61aeb5d84bb3f9afeb1ebebcafe'],
        fi_desired_amount_from:
          res.data['c679fe66cb11de662097f31eeda54d0a36301da4'],
        contract_start_date:
          res.data['c73437498b1c9d8d43d25649445df4c7de58cac0'],
        al_referral_customer:
          res.data['cad6f6403ae65b0bba664d1c744a44dffa578adc'],
        pr_referral_customer:
          res.data['ce93be6ed9adc932c69b0fffbed6559f66f64f95'],
        fi_desired_repayment_period:
          res.data['d6cc9ca876aeb47f607eeab72ec446fd5a876bf9'],
        remark: res.data['d044de6ddcdce1eeddf359d8483fb516d5d46fa9'],
        st_industry_parent:
          res.data['da1db45f9014f7efe48bf2168d6046191ce358bf'],
        consultation_content_form:
          res.data['dbb7bd710be7521a75ea01731a139adc2b11b842'],
        qu_desired_plan: res.data['e6ba149a3f5cd26d7a896543281c24d75ac32adf'],
        su_execution_date: res.data['e7f06148fb5c7720189a2fdfaf73c59914a37f6a'],
        ea_support_center_manager:
          res.data['e77fead92909d917cef6db250caa2da81bdf5612'],
        fi_institution_type:
          res.data['e7448d14f64c5aaf1a86ef5702c03888849b1a74'],
        st_industry_grand_parent:
          res.data['f39bb38d4941bcdd610cc19ca0ee534c785da920'],
        su_subsidy_type: res.data['f4432584ddc848a97bdefc55f7fb2965800082aa'],
      }

      return deal
    })
    .catch((err) => {
      console.log(err)
    })
}

// 取引更新
export const updateDeal = async (
  deal: DealFields,
  apiToken: string
): Promise<boolean> => {
  const dealParam = {
    user_id: deal.dealUserId,
    pipeline_id: deal.pipelineId,
    stage_id: deal.stageId,
    expected_close_date: deal.expectedCloseDate,
    probability: deal.probability,
    lost_reason: deal.lostReason,
    '0fd1ec0af3f72840103fbb14b62a1af8c861bee4': deal.remarkSecond,
    '01c8deb691ec8333873492217a38a43caf17ec8b': deal.inquiryRoute,
    '1f06a882cd39b2c97c916f714859a7464d9a059c': deal.financeInterviewTime,
    '02d4b3d3121dab78689f55f1f73af3f76f20002f': deal.prBusinessNegotiationDate,
    '2c30d9f0d2073d080cad039ff2ed13587b9ab465': deal.documentStorageLocation,
    '4b24900995863cb80a1501b391f88aeff7b6dde4': deal.prReferralFee,
    '4f38aaf746058b6bcf2ca1608a1a6489426d591c': deal.desiredAmountTo,
    '5b26d0dadfddb370c0d275d18b75cd41bf819a3a': deal.inBillFlg,
    '6b798b37d97d46d965cbc1de473f7f921476ca99': deal.interviewType,
    //'6cf165d55b4d0958c2ac622bd560b09d2d321649': deal.manager,
    '8d3a806c4006b869d5d8ef5722bbd27ddca69424': deal.inSubject,
    '9dd893760da629ef7bfe921ceaa88b01c8768601': deal.referralSource,
    '24d69bcae2316f34be6731fe3b94d9dedce067b1': deal.quHowKnow,
    '35c09166609bb95dd2f653ac6a22791cd00f0039': deal.financeInterviewDate,
    '42bbd510fd9fd276d7697bd60a3b0eb74fbcc22b': deal.inSendType,
    '44aa887f857a803eefd72f5569f4503bbfeda4a2': deal.acPlanFee,
    '46b091ca8f1d238709542f7c9a03f5b3935bd812': deal.desiredRepaymentDay,
    '60deb1bf7fd7870bb527a35d6bf51f792b7f1644': deal.suDocumentSubmitDate,
    '062c249dd76ac83c9ca56bdeaaffeb6aa13c131f': deal.inOriginTransactionDeal,
    //'94be9399d59da403942ef320c2b273c92cf1d758': deal.oldManager,
    '239ef96662c97835119b7d2526db7c160df8b17c': deal.desiredDefermentPeriod,
    '615d40bdc49464b0f7dfd56b431784911b437552': deal.paApprovalNumber,
    '724b1417b6390bb6a5ad981ca4d016d79b7f6dff': deal.debitInstitution,
    '902cbcb2c9db47382249301b863616dca515be0c': deal.eaApplicationDate,
    '995aed0bbd9c9a97171a9edfe39b726bf63f9530': deal.subscription?.join(','),
    '998fb56fd63eeffb1213a3cd3413f63b21fffcf8': deal.inBillDate,
    '2138c4052a351b67671b5a4caa91f3fda24153c0': deal.suAdoptDate,
    '4609d93564a1a5ff7c7662dee7a57fe3f4a1ea2f': deal.eaSupportCenter,
    '5325fdb1bd8165202176ed85a02fd4ff63436bef': deal.desiredDate,
    '490b3bfef3791af7730a739eb204b38980f1e6ba': deal.financeHearing,
    '8186a9f01bacc95a698a2d71130a650bcd2ac2c6': deal.consultationContent,
    '77636fe40481a76b943b04da6490701303549d1b': deal.interestRate,
    '94300d29a7b3819eaa14c3ff727f97cfe45de72d': deal.alServiceChild,
    '113376ae968c477de01bc31143d043b5c385d21f': deal.requestedDocument,
    '06756327a7ae244171befdd30b8741989c994413': deal.quHopeMoveSeason,
    '96976366eeee865125668489cb1cc5530496c17f': deal.debitBranch,
    '02561687523a48edd224095dea1bf7b04803ca5d': deal.inPaymentDay,
    '2758653158cf0395f5fb67b11bd8b5aa282ce716': deal.decidedAmount,
    '5383648224ba960a8cd628623ddc605bd486f2a5': deal.institution,
    //'96101668961ac37d7bd4b86b9d7634ef2f8a1138': deal.contractTargetMonth,
    a4f6c3d6f4d589ddfcc30a909af0f279ba0bd194: deal.suDecidedAmount,
    a0aa23d4afd98bd9a6015523f7c1a47b464ec485: deal.referralSourceManager,
    a55c6719d71ef790698f46d505f3987d10830b76: deal.branch,
    a67f4de28688f8a1610c3a9374f621c6a27645ba: deal.alServiceParent,
    a123eb6f2704aed6781ac3761df787c97662cd81: deal.institutionManager,
    a724568d49134d0f3d9b5d9f923195cbdeb8f789: deal.alCompany,
    aaaf15dbee89bc4f02cc1901ffef7801795a7855: deal.inPaymentStatus,
    af1c84f3514d6a550f8eb67ea4d008f9a504d447: deal.prBusinessNegotiationTime,
    afa4acf94f1042675bd180f707996d6847aaccb9: deal.inPaymentDeadline,
    //'b5a5fbd79414538c82158943c77f9d4751a53fa0': deal.zoomUrl,
    befab0d3786f48301b6428156ba9eba20d5633db: deal.paPaymentDeadline,
    c4b94baf74c3bb991303cab02fbfc07abf93b941: deal.acContractPeriod,
    c9c9cf092f9ec61aeb5d84bb3f9afeb1ebebcafe: deal.woProduction?.join(','),
    c679fe66cb11de662097f31eeda54d0a36301da4: deal.desiredAmountFrom,
    cad6f6403ae65b0bba664d1c744a44dffa578adc: deal.alReferralCustomer,
    ce93be6ed9adc932c69b0fffbed6559f66f64f95: deal.prReferralCustomer,
    d6cc9ca876aeb47f607eeab72ec446fd5a876bf9: deal.desiredRepaymentPeriod,
    d044de6ddcdce1eeddf359d8483fb516d5d46fa9: deal.remark,
    dbb7bd710be7521a75ea01731a139adc2b11b842: deal.consultationContentForm,
    e6ba149a3f5cd26d7a896543281c24d75ac32adf: deal.quDesiredPlan,
    e7f06148fb5c7720189a2fdfaf73c59914a37f6a: deal.suExecutionDate,
    e77fead92909d917cef6db250caa2da81bdf5612: deal.eaSupportCenterManager,
    e7448d14f64c5aaf1a86ef5702c03888849b1a74: deal.institutionType,
    f4432584ddc848a97bdefc55f7fb2965800082aa: deal.suSubsidyType,
    c73437498b1c9d8d43d25649445df4c7de58cac0: deal.contractStartDate,
    '0ebc9dc5cf0d16be355c7d28a4fa5ad69eb93531': deal.foundedStatus,
    f39bb38d4941bcdd610cc19ca0ee534c785da920:
      deal.statisticsIndustryGrandparent,
    da1db45f9014f7efe48bf2168d6046191ce358bf: deal.statisticsIndustryParent,
  }

  return client
    .put(`deals/${deal.dealId}?api_token=${apiToken}`, dealParam)
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

// 成約処理
export const contractDeal = async (
  dealId: number,
  apiToken: string
): Promise<boolean> => {
  return client
    .put(`deals/${dealId}?api_token=${apiToken}`, { status: 'won' })
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

// 失注処理
export const lostDeal = async (
  dealId: number,
  lostReason: string,
  apiToken: string
): Promise<boolean> => {
  return client
    .put(`deals/${dealId}?api_token=${apiToken}`, {
      status: 'lost',
      lost_reason: lostReason,
    })
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

/**
 * 時間を小数点形式に変換
 * 例）18:30 → 18.5
 * @param timeStr
 * @returns
 */
const convertTimeToDecimal = (timeStr: string): number => {
  const [hours, minutes] = timeStr.split(':')
  const decimalMinutes = parseInt(minutes, 10) / 60

  // 分が0の場合は小数部分を追加せずに整数として返す
  return decimalMinutes === 0
    ? parseInt(hours, 10)
    : parseInt(hours, 10) + decimalMinutes
}

// 共通関数：オーナーIDに基づいて適切な値を取得
const getOwnerIdOrFallback = (ownerId: number, fallbackId: number): number =>
  ownerId == 99999992 ? fallbackId : ownerId

// 共通関数：時間と日付のフォーマット
const formatTime = (time: string): string => format(new Date(time), 'HH:mm')

/**
 * 送客時に相談内容に記載するテンプレート文を作成
 * @param params
 * @param authUserName
 * @param ownerName
 * @param taskStartTime
 * @returns
 */
const createConsultationContent = (
  params: FreeDialFields,
  authUserName: string | null,
  ownerName: string,
  taskStartTime: string
): string => {
  const prefectureName = PREFECTURE.filter(
    (v) => v.id == params.openingPrefecture
  )[0].prefecture_name

  const industryName = INDUSTRY_GRANDPARENT.filter(
    (v) => v.pipedrive_field_id == params.industryGrandparent
  )[0].industry_grandparent_name

  let taskStartDateMMdd = ''
  if (params.taskStartDate) {
    taskStartDateMMdd = format(new Date(params.taskStartDate), 'MMdd')
  }

  const sendDate =
    format(new Date(), 'MMdd') +
    ' ' +
    authUserName +
    ' ' +
    taskStartDateMMdd +
    ' ' +
    convertTimeToDecimal(taskStartTime) +
    '- ' +
    ownerName

  const consultationContent = `事業内容：${industryName}\n開業場所：${prefectureName}\n開業時期：${params.openingTime}\n${sendDate}\n`

  return consultationContent
}

/**
 * フリーダイヤル新規受付（新規客）
 * @param params
 * @param apiToken
 * @param authUserName
 * @returns
 */
export const createFreeDialDeal = async (
  params: FreeDialFields,
  apiToken: string,
  authUserName: string | null
): Promise<boolean> => {
  // 組織を作成
  return client
    .post(`organizations?api_token=${apiToken}`, {
      name: params.orgName,
      owner_id: getOwnerIdOrFallback(params.ownerId, 15807800),
      f6874c82007aa59543091a929daba42f0f3fa893: params.tel,
      '762cc82a411713cb704fc0b195fe4524464af031': params.name,
      ca10c3817005b4fafaa49f3891a8ce078393685e: params.kana,
      d0693aea120dc3312bf85acb93ff47985d3dc321: params.industryGrandparent,
      ee40cb99dc50c6816ca23a9a80496bf774c8d444: params.openingPrefecture,
      '3bbfa4fdb40230399dff6bd41d5a50f67f7287f4': params.openingTime,
    })
    .then(async (org) => {
      const hash = (() => {
        const source =
          '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        let str = ''
        for (let i = 1; i <= 22; i++) {
          str += source.charAt(Math.floor(Math.random() * source.length))
        }
        return str
      })()

      // オーナーの情報を取得
      const owner = FINANCE_SUPPORT_MEMBER.filter(
        (v) => v.id == params.ownerId
      )[0]

      // 人物を作成
      const person = await client.post(`persons?api_token=${apiToken}`, {
        name: params.name,
        owner_id: getOwnerIdOrFallback(params.ownerId, 15807800),
        phone: params.tel,
        org_id: org.data.id,
        email: params.email,
        '3b08ad106a3437a6ed42ccf4636f84a3780091c1': params.kana,
        f52ec43d146d18d0f7b334b07fb452572af761d3: hash, // ハッシュ値
      })

      const taskStartTime = formatTime(params.taskStartTime)

      const consultationContent = createConsultationContent(
        params,
        authUserName,
        owner.name,
        taskStartTime
      )

      // 取引を作成
      const deal = await client.post(`deals?api_token=${apiToken}`, {
        title: params.tel,
        user_id: getOwnerIdOrFallback(owner.id, 15807800), // 富田のみ村口登録
        person_id: person.data.id,
        org_id: org.data.id,
        pipeline_id: getPipelineId(owner.type),
        stage_id: getStageId(owner),
        '01c8deb691ec8333873492217a38a43caf17ec8b': params.inquiryRoute,
        '8186a9f01bacc95a698a2d71130a650bcd2ac2c6':
          consultationContent + params.consultationContent,
      })

      // タスク開始時間が設定されていた場合
      if (owner.id != 12620110 && owner.id != 12620286) {
        // 入力時間を退避
        const tmpTaskStartTime = format(
          new Date(params.taskStartDate + ' ' + taskStartTime),
          'HH時mm分'
        )
        // タスクの開始時間(標準時基準のため9時間調整)
        const taskStartClock = format(
          addHours(new Date(params.taskStartDate + ' ' + taskStartTime), -9),
          'yyyy-MM-dd HH:mm'
        )

        const startDate = taskStartClock.slice(0, 10)
        const startTime = taskStartClock.slice(-5)

        // アクティビティを作成
        await client.post(`activities?api_token=${apiToken}`, {
          due_date: startDate,
          due_time: startTime,
          duration: owner.duration, // 担当者によって変更
          deal_id: deal.data.id,
          person_id: person.data.id,
          org_id: org.data.id,
          subject: `${tmpTaskStartTime}〜 架電 ${params.name}様`,
          type: '電話ヒアリング',
          user_id: getOwnerIdOrFallback(owner.id, 15807800), // 富田のみ事務2登録
        })
      }

      // NOTE:メールアドレスが入力された場合診断メール送信
      // 電話問い合わせはFUTOKORO-pipedriveが連携されないため同意を取るため診断フォームへ案内する
      if (params.email) {
        await back
          .post(`/mail/diagnosis`, {
            name: params.name,
            email: params.email,
            hash: hash,
          })
          .catch((err) => {
            throw new Error(err.response.data)
          })
      }
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

/**
 * フリーダイヤル新規受付（既存客）
 * @param params
 * @param apiToken
 * @param authUserName
 * @returns
 */
export const existFreeDialDeal = async (
  params: FreeDialFields,
  apiToken: string,
  authUserName: string | null
): Promise<boolean> => {
  // 人物を更新
  return client
    .put(`persons/${params.existPhone}?api_token=${apiToken}`, {
      name: params.name,
      owner_id: getOwnerIdOrFallback(params.ownerId, 15807800),
      phone: params.tel,
      '3b08ad106a3437a6ed42ccf4636f84a3780091c1': params.kana,
    })

    .then(async (person) => {
      // オーナーの情報を取得
      const owner = FINANCE_SUPPORT_MEMBER.filter(
        (v) => v.id == params.ownerId
      )[0]

      // 組織を更新
      const org = await client.put(
        `organizations/${person.data.org_id.value}?api_token=${apiToken}`,
        {
          name: params.orgName,
          owner_id: getOwnerIdOrFallback(owner.id, 15807800),
          f6874c82007aa59543091a929daba42f0f3fa893: params.tel,
          '762cc82a411713cb704fc0b195fe4524464af031': params.name,
          ca10c3817005b4fafaa49f3891a8ce078393685e: params.kana,
          d0693aea120dc3312bf85acb93ff47985d3dc321: params.industryGrandparent,
          ee40cb99dc50c6816ca23a9a80496bf774c8d444: params.openingPrefecture,
          '3bbfa4fdb40230399dff6bd41d5a50f67f7287f4': params.openingTime,
        }
      )

      const taskStartTime = formatTime(params.taskStartTime)

      const consultationContent = createConsultationContent(
        params,
        authUserName,
        owner.name,
        taskStartTime
      )

      // 取引を作成
      const deal = await client.post(`deals?api_token=${apiToken}`, {
        title: params.tel,
        user_id: getOwnerIdOrFallback(owner.id, 15807800), // 富田のみ村口登録
        person_id: person.data.id,
        org_id: org.data.id,
        pipeline_id: getPipelineId(owner.type),
        stage_id: getStageId(owner),
        '01c8deb691ec8333873492217a38a43caf17ec8b': params.inquiryRoute,
        '8186a9f01bacc95a698a2d71130a650bcd2ac2c6':
          consultationContent + params.consultationContent,
      })

      // タスク開始時間が設定されていた場合
      if (owner.id != 12620110 && owner.id != 12620286) {
        // 入力時間を退避
        const tmpTaskStartTime = format(
          new Date(params.taskStartDate + ' ' + taskStartTime),
          'HH時mm分'
        )
        // タスクの開始時間(標準時基準のため9時間調整)
        const taskStartClock = format(
          addHours(new Date(params.taskStartDate + ' ' + taskStartTime), -9),
          'yyyy-MM-dd HH:mm'
        )

        const startDate = taskStartClock.slice(0, 10)
        const startTime = taskStartClock.slice(-5)

        // アクティビティを作成
        await client.post(`activities?api_token=${apiToken}`, {
          due_date: startDate,
          due_time: startTime,
          duration: owner.duration, // 融資担当者によって変更
          deal_id: deal.data.id,
          person_id: person.data.id,
          org_id: org.data.id,
          subject: `${tmpTaskStartTime}〜 架電 ${params.name}様`,
          type: '電話ヒアリング',
          user_id: getOwnerIdOrFallback(owner.id, 15807800), // 富田のみ事務2登録
        })
      }

      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

// pipedrive取引存在チェック
export const checkDealById = async (id: string | undefined): Promise<void> => {
  await client
    .get(`deals/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .catch((err) => {
      throw new Error(err.message)
    })
}
